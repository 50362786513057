import { useEffect, useState } from "react"
import { NavLink } from 'react-router-dom'
import { t } from "i18next"
import { feedbackPolicyID } from "../config"
import { getRequestOptions } from "../helpers/apiHelper"
import { ShowLoader, HideLoader, FormatDate } from "../functions"
import { Breadcrumbs } from "../breadcrumbs"
import { ContactsAside } from "../components/contactsaside"
import { TablePreloader } from "../components/TablePreloader"
import { AlertPopup } from "../components/AlertPopup"

import './../assets/css/staticpages.css'

import { ReactComponent as SubmitFeedback } from './../assets/svg/gridfeedback.svg'
import { ReactComponent as Orders } from './../assets/svg/gridorders.svg'
import { ReactComponent as Account } from './../assets/svg/gridaccount.svg'

const FeedbackContent = () => {

    const [historyData,setHistoryData] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [feedbackPolicyText, setFeedbackPolicyText] = useState('')
    const [isFeedbackPolicyAlert,setIsFeedbackPolicyAlert] = useState(false)
    
    useEffect(()=>{
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/feedbacks/?entity_account_id=${localStorage.getItem('account.entity.id')}&realKey=true&include=feedbackType,status`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data && json.data.forEach(element => {                
                const tempObj = {
                    id: element.id,
                    id_real: element.id_real,
                    processID: element.process_id,
                    processID_real: element.process_id_real,
                    status: element.status && element.status.data && element.status.data.matchcode,
                    color: element.status && element.status.data && element.status.data.color,
                    orderID: element.document_id_real,
                    orderDate: FormatDate(element.created_at),
                    type: element.feedbackType && element.feedbackType.data && element.feedbackType.data.matchcode,
                    complaintdate: FormatDate(element.updated_at),
                }
                setHistoryData(prevState => ([...prevState, tempObj]))
            })
            setDataLoaded(true)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }, [])

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/privacy-policies-public`,getRequestOptions())
        .then(response => response.json())
        .then(json => json?.data?.length && setFeedbackPolicyText(json.data.filter(e => e.real_id === feedbackPolicyID)[0].body))
    }, [])

    return (
        <div className="wrapper">
            <div className="feedback__columns">
                <div>
                    <h1>{t('niki.b2b.menu.feedback')}</h1> 
                    <div className="feedback__columns__grey">
                        <div className="feedback__columns__grey__header">{t('niki.b2b.feedback.alert.title')}</div>
                        <div className="feedback__columns__grey__text">{t('niki.b2b.feedback.alert.text1')} {t('niki.b2b.feedback.alert.text2')}</div>
                        <button className="feedback__columns__grey__view_policy" onClick={() => setIsFeedbackPolicyAlert(true)}>{t('niki.b2b.feedback.alert.policy')}</button>
                    </div>
                    <div className="feedback_account_options">{t('niki.b2b.feedback.accountoptions')}</div>
                    <ul className="feedback_account_options__grid">
                        <li><NavLink to="/submit-feedback/"><SubmitFeedback />{t('niki.b2b.feedback.submit')}</NavLink></li>
                        <li><NavLink to="/orders/"><Orders />{t('niki.b2b.account.orders')}</NavLink></li>
                        <li><NavLink to="/account/"><Account />{t('niki.b2b.account.account')}</NavLink></li>
                    </ul>
                </div>
                <div>
                    <div className="contacts__support__header">{t('niki.b2b.account.supportteam')}</div>
                    <ContactsAside />
                </div>
            </div>

            <hr className="feedback__hr" />

            <div className="feedback__history">
                <div className="feedback__history__header">{t('niki.b2b.feedback.history')}</div>
                {dataLoaded
                    ? <table className="table">
                        <thead>
                            <tr>
                                <th>{t('niki.b2b.feedback.id')}</th>
                                <th>{t('niki.b2b.debts.processid')}</th>
                                <th>{t('niki.b2b.feedback.orderid')}</th>
                                <th>{t('niki.b2b.debts.orderdate')}</th>
                                <th>{t('niki.b2b.feedback.complaintdate')}</th>
                                <th>{t('niki.b2b.feedback.type')}</th>
                                <th>{t('niki.b2b.feedback.status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyData.length > 0 && historyData.map((e,i)=>
                                <tr key={`historydata${i}`}>
                                    <td><NavLink to={`/feedback-status/${e.id}/`}>{e.id_real}</NavLink></td>
                                    <td>{e.processID_real}</td>
                                    <td>{e.orderID}</td>
                                    <td>{e.orderDate}</td>
                                    <td>{e.complaintdate}</td>
                                    <td>{e.type}</td>
                                    <td><div style={{ background: e.color, borderRadius: 10}}>{e.status}</div></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    : <TablePreloader />
                }
            </div>

            {isFeedbackPolicyAlert && <AlertPopup text={feedbackPolicyText} closeAlert={() => setIsFeedbackPolicyAlert(false)} /> }
        </div>
    )
}

export const Feedback = () => {
    return (
        <>
            <Breadcrumbs itemlist={[
                {'url':'/account/','title':t('niki.b2b.menu.myaccount')},
                {'url':'/feedback/','title':t('niki.b2b.menu.feedback')},
            ]} />
            <FeedbackContent />
        </>
    )
}