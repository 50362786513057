import { createSlice } from "@reduxjs/toolkit"

const allProductsSlice = createSlice({
    name: 'allproducts',
    initialState: {
        isClickOnBrand: false,
        isClickFavorites: false,
    },
    reducers: {
        setIsClickOnBrand(state, action) {
            state.isClickOnBrand = action.payload
        },
        setIsClickFavorites(state, action) {
            state.isClickFavorites = action.payload
        },
    }
})

export default allProductsSlice.reducer
export const { setIsClickOnBrand, setIsClickFavorites } = allProductsSlice.actions