import { useState, useEffect } from "react"
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { t } from "i18next"
import { meilisearchUrl, xCompanyID } from '../config'
import { guruRequestOptions, getMeilisearchIndexName } from "../helpers/apiHelper"
import { setIsClickOnBrand } from "../reduxToolkit/allproduct"
import { ShowLoader, HideLoader } from "../functions"
import { Breadcrumbs } from "../breadcrumbs"

import "./../assets/css/staticpages.css"

// import { ReactComponent as Facebook } from './../assets/svg/facebook.svg'
// import { ReactComponent as Linkedin } from './../assets/svg/linkedin.svg'
// import { ReactComponent as Instagram } from './../assets/svg/instagram.svg'
// import { ReactComponent as Home } from './../assets/svg/homegreyfilled.svg'

const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","0","1","2","3","4","5","6","7","8","9"]
let letterLength = []
let brandsAlphabet = []
// let brandsDesc = {}
// let brandsFacebook = {}
// let brandsLinkedin = {}
// let brandsInstagram = {}
// let brandsWebsite = {}

const getHighlightedText = (text, highlight) => {
    if (text) {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
        return <span> { parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { background: '#FFE600' } : {} }>
                { part }
            </span>)
        } </span>
    }
}

const fetchBrands = async () => {
    const response = await fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("items")}/search?facets=${xCompanyID === '4' ? 'brand' : 'trademark'}.name&limit=0`, guruRequestOptions)
    const json = await response.json()
    return json
}

// async function fetchStartData() { 
//     const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/brand-list`,getRequestOptions())
//     const json = await response.json()
//     return json
// }

// useEffect(() => {
    //     ShowLoader()
    //     fetchStartData().then(json => {
    //         json.data.forEach((el)=>{
    //             const brandName = el.name.trim()
    //             brandsAlphabet.push(brandName)
    //             // if (el.description && el.description[0]) brandsDesc[brandName] = el.description[0].replace(/<\/?[a-zA-Z]+>/gi,'')
    //             // if (el.socials) {
    //             //     el.socials.forEach(social => {
    //             //         if (social.matchcode === 'Website') brandsWebsite[brandName] = social.value
    //             //         if (social.matchcode === 'Facebook') brandsFacebook[brandName] = social.value
    //             //         if (social.matchcode === 'Instagram') brandsInstagram[brandName] = social.value
    //             //         if (social.matchcode === 'Linkedin') brandsLinkedin[brandName] = social.value
    //             //     })
    //             // }
    //         })
    //         brandsAlphabet.sort()
    //         alphabet.forEach(letter=>{
    //             letterLength[letter] = brandsAlphabet.filter(brand => brand.startsWith(letter)).length
    //         })
    //         setDataLoaded(true)
    //     })
    //     .finally(() => HideLoader())
        
    //     return () => {
    //         letterLength = []
    //         brandsAlphabet = []
    //     }
// },[])

export const Brands = () => {

    const dispatch = useDispatch()

    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        ShowLoader()
        fetchBrands()
            .then(json => {
                if(json?.facetDistribution[`${xCompanyID === '4' ? 'brand' : 'trademark'}.name`]) {
                    brandsAlphabet = Object.keys(json.facetDistribution[`${xCompanyID === '4' ? 'brand' : 'trademark'}.name`])
                }
                brandsAlphabet.sort()
                alphabet.forEach(letter=>{
                    letterLength[letter] = brandsAlphabet.filter(brand => brand.startsWith(letter)).length
                })
                setDataLoaded(true)
            })
            .finally(() => HideLoader())
    },[])
    
    const ToggleBrandInfo = e => {
        e.target.parentNode.classList.toggle('active')
    }
    const [weSearch,setWeSearch] = useState('')
    const SearchBrands = e => {
        const searchWord = e.target.value.toLowerCase().trim()
        setWeSearch(searchWord)
        if (searchWord.length > 0) {
            document.querySelector('.brands_letters').classList.add('in_search')
            document.querySelectorAll('.brands__big_letter').forEach(el => el.style.display = 'none')
            document.querySelectorAll('.brands__single_brand').forEach(el => el.style.display = 'none')
            document.querySelectorAll(`.brands__single_brand[data-search*="${searchWord}"]`).forEach(el => el.style.display = 'block')
        } else {
            document.querySelector('.brands_letters').classList.remove('in_search')
            document.querySelectorAll('.brands__big_letter').forEach(el => el.style.display = 'block')
            document.querySelectorAll('.brands__single_brand').forEach(el => el.style.display = 'block')
        }
    }
    return (
        <>
            <Breadcrumbs itemlist={[{'url':'/brands/','title':t('niki.b2b.menu.brands')}]} />
            <div className="wrapper">
                <h1>{t('niki.b2b.menu.brands')}</h1>
                {dataLoaded ? (
                    <>
                        <input className="form-control brands__search" type="search" placeholder={t('niki.b2b.brands.search')} onChange={SearchBrands} value={weSearch} />
                        <ul className="brands_alphabet">{alphabet.map((el,i)=><li key={`letter${i}`}><a data-length={letterLength[el]} href={`/brands/#${el}`}>{el}</a></li>)}</ul>
                        <div className="brands_letters">{alphabet.filter(el => letterLength[el] > 0).map((el,i)=>
                            <div key={`letterblock${i}`} id={el} className="brands_by_letter__wrapper">
                                <div className="brands__big_letter">{el}</div>
                                <div className="brands_by_letter">
                                    {brandsAlphabet.filter(brand => brand.startsWith(el)).map((element,j)=><div className="brands__single_brand" key={`brand${j}`} data-search={element.toLowerCase().trim()}>
                                        <button className="brands__single_brand__title" onClick={ToggleBrandInfo}>{getHighlightedText(element,weSearch)}</button>
                                        <div className="brands__single_brand__info">
                                            {/* {brandsDesc[element]} */}
                                            <div className="brands__single_brand__info__footer">
                                                <NavLink onClick={() => dispatch(setIsClickOnBrand(true))} to={`/all-products/?brand=${element}`} className="btn">{t('niki.b2b.brands.seeall')}</NavLink>
                                                {/* <div className="brands__single_brand__info__footer__social">
                                                    {brandsFacebook[element] && <a href={brandsFacebook[element]} target="_blank" rel="nofollow noopener noreferrer"><Facebook /></a>}
                                                    {brandsLinkedin[element] && <a href={brandsLinkedin[element]} target="_blank" rel="nofollow noopener noreferrer"><Linkedin /></a>}
                                                    {brandsInstagram[element] && <a href={brandsInstagram[element]} target="_blank" rel="nofollow noopener noreferrer"><Instagram /></a>}
                                                    {brandsWebsite[element] && <a href={brandsWebsite[element]} target="_blank" rel="nofollow noopener noreferrer"><Home /></a>}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        )}</div>
                    </>
                ) : (
                    <>
                        <div className="search_content_placeholder content_placeholder"></div>
                        <ul className="alphabet_placeholder">{Array.from('example1234').map((e,i)=><li key={`alphabetplaceholder${i}`} className="content_placeholder"></li>)}</ul>
                        <div className="three_columns_list_placeholder">{Array.from('example').map((e,i)=><div className="brandplaceholderrow" key={`placeholderrow${i}`}>{Array.from('12345').map((ee,ii)=><div className="brandplaceholdercolumn content_placeholder" key={`placeholdercolumn${ii}`}></div>)}</div>)}</div>
                    </>
                )}
            </div>
        </>
    )
}