import {useEffect, useState} from "react"
import {t} from "i18next"
import { ShowLoader, HideLoader } from '../functions'
import { getRequestOptions } from "../helpers/apiHelper"

import {ReactComponent as ContactsPhone} from './../assets/svg/contactsphone.svg'
import {ReactComponent as ContactsEmail} from '../assets/svg/contactsemail.svg'
import  reactComponent from '../assets/svg/headeraccount.svg'

 const Contact = ({e, i, blockType}) => {
    const [avatarIsValid, setAvatarIsValid] = useState(true);

    return (
        <div className={['contacts__support__unit', blockType].join(' ')} key={`contact${i}`}>
            {avatarIsValid && e.image
                ? <img src={e.image} className="contacts__support__image" alt="avatar" onError={() => setAvatarIsValid(false)} loading="lazy"/>
                : <img src={reactComponent} className="contacts__support__image" alt="avatar" loading="lazy"/>
            }
            <div>
                <div className="contacts__support__name">{e.name}</div>
                <div className="contacts__support__post">{e.position}</div>
                <a className="contacts__support__email" href={`mailto:${e.email}`}>{e.email}</a>
                <a className="contacts__support__phone" href={`tel:${e.phone}`}>{e.phone}</a>
            </div>
            <div className="contacts__support__unit__buttons">
                <a href={`tel:${e.phone}`} className="btn btn-block btn-contacts">{t('niki.b2b.contact.callnow')}
                    <ContactsPhone/></a>
                <a href={`mailto:${e.email}`}
                   className="btn btn-info btn-block btn-contacts">{t('niki.b2b.login.email')} <ContactsEmail/></a>
            </div>
        </div>

    )
}

export const ContactsAside = props => {
    let blockType = 'standart'
    if (props.type) blockType = props.type

    const [contactsData, setContactsData] = useState([]);
    useEffect(() => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/entity-accounts/${localStorage.getItem('account.entity.id')}/company-contacts`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                setContactsData([])
                json.data.users && json.data.users.forEach(e => {
                    const tempObj = {
                        name: e.name,
                        phone: e.phone,
                        image: e.picture.medium,
                        email: e.email,
                        position: e.position,
                    }
                    setContactsData(prevState => ([...prevState, tempObj]))
                })
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }, [])

    return (
        <>
            {contactsData && contactsData.length > 0 && contactsData.map((e, i) => <Contact e={e} i={i} key={`contactsaside${i}`} blockType={blockType}/>)}
        </>
    )
}