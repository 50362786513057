import { useDispatch } from 'react-redux'
import { setIsClickFavorites } from '../../reduxToolkit/allproduct'
import { HideLoader, ShowLoader } from '../../functions'
import { getRequestOptions } from "../../helpers/apiHelper"
import { t } from 'i18next'

import heartgrey from '../../assets/svg/heartgrey.svg'
import heartred from '../../assets/svg/heartred.svg'

import '../../assets/css/product.css'
import '../../assets/css/svg.css'

const InFavorite = ({data}) => {
    const dispatch = useDispatch()

    const onClickFavorite = e => {
        const thisElement = e.target
        if (thisElement.classList.contains('true')) {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/items/${data.id}/favorites`,getRequestOptions('DELETE'))
                .then(response => {
                    if (response.ok) {
                        thisElement.classList.remove('true')
                        thisElement.classList.add('false')
                        localStorage.setItem('favorites', localStorage.getItem('favorites').split(',').filter(el => el !== data.id).join(','))
                        dispatch(setIsClickFavorites(true))
                    }
                })
                .catch(error => console.error(error))
                .finally(() => HideLoader())
        } else {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/items/${data.id}/favorites`,getRequestOptions('POST'))
                .then(response => {
                    if (response.ok) {
                        thisElement.classList.remove('false')
                        thisElement.classList.add('true')
                        if (localStorage.getItem('favorites')) {
                            let tempArr = localStorage.getItem('favorites').split(',')
                            tempArr.push(data.id)
                            localStorage.setItem('favorites', tempArr.join(','))
                            dispatch(setIsClickFavorites(true))
                        } else {
                            localStorage.setItem('favorites', data.id)
                            dispatch(setIsClickFavorites(true))
                        }
                    }
                    return response.json()
                })
                .catch(error => console.error(error))
                .finally(() => HideLoader())
        }
    }

    return (
        <button className={['product_in_listing__heart', data.productView === 'grid' ? '' : 'product_supplied_partner__tree', data.is_favorite || (localStorage.getItem('favorites') && localStorage.getItem('favorites').split(',').includes(data.id))].join(' ')}
            aria-label={t('niki.b2b.product.addtofavorites')} onClick={onClickFavorite}
            id={data.id} is_favorite={localStorage.getItem('favorites').includes(data.id) ? 'true' : 'false'}
        >
            <img src={heartgrey} alt={t('niki.b2b.product.notinfavorites')} className="heart_grey" />
            <img src={heartred} alt={t('niki.b2b.product.infavorites')} className="heart_red" />
            <div>
                {t('niki.b2b.tooltip.favorite')}
            </div>
        </button>
    )
}

export default InFavorite;