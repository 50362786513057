import { useRef } from "react"
import { t } from "i18next"
import { PriceFormat, FormatDate, GetHistoryData } from "./../functions"
import { getRequestOptions } from "./../helpers/apiHelper"

import { ReactComponent as Box } from './../assets/svg/box.svg'
import { ReactComponent as Ref } from './../assets/svg/ref.svg'
import { useEffect } from "react"
import { useState } from "react"

export const ProdHistoryPopup = ({matchcode, unit_code, qty_in_box, item_id, imgsrc, product_id, setOpenHistoryProduct}) => {
    
    const [productHistory, setProductHistory] = useState([])
    const [ProdHistory, setProdHistory] = useState([])
    const [loading, setLoading] = useState(false)
 
    useEffect(() => {
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/items/${product_id}/sale-history?account_id=${localStorage.getItem('account.entity.id')}&limit=5`,getRequestOptions())
            .then(response => response.json())
            .then(json => {
                if (json.data.length > 0) {
                    setProductHistory(json.data)
                } else {
                    setProdHistory(GetHistoryData(product_id))
                }
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
        // eslint-disable-next-line
    }, [])

    const ThisOverlayEl = useRef(null)
    const HideHistoryModal = () => {
        setOpenHistoryProduct(false)
        ThisOverlayEl.current.classList.remove('active')
    }
    
    return (
        <div className={['product_history__wrapper',`product_history__wrapper_${item_id}`].join(' ')} ref={ThisOverlayEl}>
            <div className="product_history__popup">
                <div className="product_history__popup__header">{t('niki.b2b.menu.orderhistory')}</div>
                <div className="product_history__popup__prodinfo">
                    <div className="product_history__popup__img"><img src={imgsrc} alt={matchcode} /></div>
                    <div>
                        <div className="product_history__popup__prodtitle">{matchcode}</div>
                        <div>
                            <span className="product_history__popup__prodshortinfo"><Ref />{item_id}</span>
                            <span className="product_history__popup__prodshortinfo"><Box />{qty_in_box} {unit_code}</span>
                        </div>
                    </div>
                </div>
                <div className="product_history__popup__table__wrapper">
                    <table className="product_history__popup__table">
                        <thead>
                            <tr>
                                <th>{t('niki.b2b.product.date')}</th>
                                <th>{t('niki.b2b.product.quantity')}</th>
                                <th>{t('niki.b2b.product.unitprice')}</th>
                                <th>{t('niki.b2b.cart.total')}</th>
                                <th>{t('niki.b2b.document')}</th>
                            </tr>
                        </thead>
                        
                        {loading &&
                            <tbody style={{height: 40}}>
                               <tr>
                                    <td className="content_placeholder"></td>
                                    <td className="content_placeholder"></td>
                                    <td className="content_placeholder"></td>
                                    <td className="content_placeholder"></td>
                                    <td className="content_placeholder"></td>
                                </tr>
                            </tbody>
                        }

                        {productHistory.length > 0
                            ? <tbody>
                                {productHistory.map((e, i) =>
                                    <tr key={`historytable${i}`}>
                                        <td>{e.created_at}</td>
                                        <td>{e.quantity}</td>
                                        <td>{PriceFormat(e.unit_price)}</td>
                                        <td>{PriceFormat(parseFloat(e.unit_price) * parseInt(e.quantity))}</td>
                                        <td>{e.doc_header_key && e.doc_header_id
                                            ? <a href={`/document/${e.doc_header_uuid}/`} target="_blank" rel="noreferrer">{e.doc_header_key} - {e.doc_header_serial} {e.doc_header_id}</a>
                                            : t('niki.b2b.not_found')
                                        }</td>
                                    </tr>
                                )}
                            </tbody>
                            : <tbody>
                                {ProdHistory.map((e, i) =>
                                    <tr key={`historytable${i}`}>
                                        <td>{FormatDate(e.created_at)}</td>
                                        <td>{e.quantity}</td>
                                        <td>{PriceFormat(e.unit_price)}</td>
                                        <td>{PriceFormat(parseFloat(e.unit_price) * parseInt(e.quantity))}</td>
                                        <td><a href={`/document/${e.transaction_uuid}/`} target="_blank" rel="noreferrer">{e.document_serial_number}</a></td>
                                    </tr>
                                )}
                            </tbody>
                        }
                    </table>
                </div>
                <button className="product_history__popup__close" onClick={HideHistoryModal}></button>
            </div>
            <div className="product_history__overlay" onClick={HideHistoryModal}></div>
        </div>
    )
}