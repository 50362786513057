import { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { t } from 'i18next'

import { getCartsData } from "../../helpers/apiHelper"
import { GetTotalNet, ShowLoader, HideLoader, storingCartData } from '../../functions'
import { setIsChangeAccount } from '../../reduxToolkit/universalSlice'

import '../../assets/css/header.css'

import { ReactComponent as Logout } from '../../assets/svg/logout.svg'
import { ReactComponent as AccountOverview } from '../../assets/svg/accountoverview.svg'
import { ReactComponent as AccountBalance } from '../../assets/svg/accountbalance.svg'
import { ReactComponent as OrderHistory } from '../../assets/svg/orderhistory.svg'
import { ReactComponent as InvoicesReturns } from '../../assets/svg/invoicesreturns.svg'
import { ReactComponent as AddressesContacts } from '../../assets/svg/addressescontacts.svg'
import { ReactComponent as Complaints } from '../../assets/svg/complaints.svg'
import { ReactComponent as MyShoppingCarts } from '../../assets/svg/myshoppingcarts.svg'
import { ReactComponent as FavoriteProducts } from '../../assets/svg/favoriteproducts.svg'

const FormatDate = localDate => {
    const fullDate = new Date(localDate)
    return `${String(fullDate.getDate()).padStart(2, '0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}

export const AccountPopup = ({ closePopup }) => {
    
    const dispatch = useDispatch()
    // const navigate = useNavigate()

    const accountPopupWrapper = useRef(null)

    const [shoppingCartsData, setShoppingCartsData] = useState([])
    const [isClosePopup, setIsClosePopup] = useState(false)

    useEffect(() => {
        let timer;

        if (isClosePopup) {
            clearTimeout(timer);
            timer = setTimeout(() => closePopup(), 400)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isClosePopup])
    
    useEffect(() => {
        ShowLoader()
        getCartsData('', true)
        .then(json => {
            json.data && json.data.forEach(e => {
                const tempObj = {
                    id: e.id_real,
                    id_hash: e.id,
                    uuid: e.uuid,
                    date: FormatDate(e.created_at),
                    cartname: e.matchcode || t('niki.b2b.cartflow.cart'),
                    itemscount: (e.cartDetails && e.cartDetails.data && e.cartDetails.data.length) || 0,
                    totalnet: (e.cartDetails && e.cartDetails.data && e.cartDetails.data.length > 0 && GetTotalNet(e.cartDetails.data)) || 0
                }
                setShoppingCartsData(prevState => ([...prevState, tempObj]))
            })
        })
        .catch(error => console.error(error))
        .finally(() => {
            HideLoader()
            accountPopupWrapper.current.classList.add('active')
        })
    }, [])
    
    const HideAccountPopup = () => {
        setIsClosePopup(true)
        accountPopupWrapper.current.classList.remove('active')
    }

    const LogoutAccount = () => {
        const country = localStorage.getItem('locale.full')
        const language = localStorage.getItem('locale')
        const lastUrl = localStorage.getItem('current.url')

        localStorage.clear()
        localStorage.setItem('current.url', lastUrl)
        localStorage.setItem('locale.full', country)
        localStorage.setItem('locale',language)
        window.location.href = '/'
    }

    const openShoppingCart = val => {
        storingCartData(val.uuid, val.id, val.id_hash)
        localStorage.setItem('cart_data', `${val.uuid},${val.id},${val.id_hash}`)
        window.location.href = '/myshoppingcart/'
        // dispatch(setIsChangeSelectedShoppingCard(true))
        // navigate('/myshoppingcart/')
    }

    return (
        <div className="account_popup__wrapper" ref={accountPopupWrapper}>
            <div className="account_popup__wrapper__container">
                <div className="account_popup">
                    <div>
                        <div className="account_popup__header">{t('niki.b2b.cart.shoppingcarts')}</div>
                        <ul className="account_popup__list">
                            <li onClick={HideAccountPopup}><NavLink to="/shoppingcarts/"><MyShoppingCarts />{t('niki.b2b.cart.myshoppingcarts')}</NavLink></li>
                            <li onClick={() => window.location.pathname === '/all-products/' ? null : HideAccountPopup()}>
                                {window.location.pathname === '/all-products/'
                                    ? <button disabled={true} className="border_bottom"> <FavoriteProducts /> {t('niki.b2b.menu.favoriteproducts')}</button>
                                    : <NavLink to="/all-products/?show=favorites" className="border_bottom"><FavoriteProducts />{t('niki.b2b.menu.favoriteproducts')}</NavLink>
                                }
                            </li>
                            {shoppingCartsData.map((e,i) =>
                                <li key={`headershoppingcart${i}`}>
                                    <button onClick={() => openShoppingCart(e)} className="showppingcartid">
                                        {e.id}&nbsp;{e.cartname ? e.cartname : t('niki.b2b.cartflow.cart')}
                                    </button>
                                    {/* <button onClick={() => openShoppingCart(e)} href={`/myshoppingcart/`} className="showppingcartid">
                                        {e.id}&nbsp;{e.cartname ? e.cartname : t('niki.b2b.cartflow.cart')}
                                    </button> */}
                                </li>
                            )}
                        </ul>
                    </div>
                    <div>
                        <div className="account_popup__header">{t('niki.b2b.account.my')}</div>
                        <ul className="account_popup__list">
                            <li onClick={HideAccountPopup}><NavLink to="/account/"><AccountOverview />{t('niki.b2b.header.accountoverview')}</NavLink></li>
                            <li onClick={HideAccountPopup}><NavLink to="/debts/"><AccountBalance />{t('niki.b2b.header.accountbalance')}</NavLink></li>
                            <li onClick={HideAccountPopup}><NavLink to="/orders/"><OrderHistory />{t('niki.b2b.menu.orderhistory')}</NavLink></li>
                            <li onClick={HideAccountPopup}><NavLink to="/invoices/"><InvoicesReturns />{t('niki.b2b.menu.invoicesreturns')}</NavLink></li>
                            <li onClick={HideAccountPopup}><NavLink to="/deliveryaddresses/"><AddressesContacts />{t('niki.b2b.menu.addressescontacts')}</NavLink></li>
                            <li onClick={HideAccountPopup}><NavLink to="/feedback/"><Complaints />{t('niki.b2b.menu.feedback')}</NavLink></li>
                            {localStorage.getItem('has_many_entity_account') === 'true' && <li onClick={HideAccountPopup}>
                                <button onClick={() => {dispatch(setIsChangeAccount(true))}}><AccountOverview />{t('niki.b2b.change_account')}</button>
                            </li>}
                            <li><button onClick={LogoutAccount}><Logout />{t('niki.b2b.menu.logout')}</button></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="account_popup__overlay" onClick={HideAccountPopup}></div>
        </div>
    )
}