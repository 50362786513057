import { useEffect, useState } from "react"
import { t } from "i18next"
import { catalogsTypeID } from "../../config"
import { ShowLoader, HideLoader, FormatDate } from "../../functions"
import { getRequestOptions } from "../../helpers/apiHelper"

import { Breadcrumbs } from "../../breadcrumbs"
import { TablePreloader } from "../TablePreloader"
import LoadableImage from "../LoadableImage/LoadableImage"

import { ReactComponent as Download } from '../../assets/svg/productcatalogdownload.svg'

export const ProductCatalogs = () => {

    const [catalogsData, setCatalogsData] = useState([])
    const [currentCatalog, setCurrentCatalog] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(()=>{
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/marketing-campaigns?include=attachments,items&search=typeableType.id:${catalogsTypeID}&limit=30`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            setCatalogsData([])
            let currentAssigned = false
            const data = json.data || []
            data.sort((a, b) => {
                return new Date(a.created_at) - new Date(b.created_at);
            }).reverse()
            data.length > 0 && data.forEach((e, i) => {
                let pdfPath = ''
                let thumbPath = ''
                if (e.attachments && e.attachments.data && e.attachments.data.length > 0) {
                    e.attachments.data.reverse().forEach(el => {
                        if (el.extension === 'pdf') {
                            pdfPath = el.awsFileUrl
                        } else {
                            thumbPath = el.awsFileUrl
                        } 
                    })
                }
                const tempObj = {
                    id: e.id,
                    title: e.matchcode,
                    pdf: pdfPath,
                    thumb: thumbPath,
                    from: FormatDate(e.start_at),
                    to: FormatDate(e.finish_at),
                }
                if (thumbPath.length > 0) {
                    setCatalogsData(prevState => ([...prevState, tempObj]))
                    if (!currentAssigned) {
                        setCurrentCatalog(tempObj)
                        currentAssigned = true
                    }
                }
            })
        })
        .catch(error => console.error(error))
        .finally(() => {
            HideLoader()
            setDataLoaded(true)
        })
    }, [])

    return (
        <>
            <Breadcrumbs itemlist={[{'url':'/marketing/','title':t('niki.b2b.menu.marketing')},{'url':'/product-catalogs/','title':t('niki.b2b.marketing.catalogs')}]} />
            <div className="wrapper">
                <h1 className="promotions__h1">{t('niki.b2b.marketing.catalogs')}</h1>
                <div className="promotions__two_columns">
                    <div className="promotions__two_columns__first">
                        <a href={currentCatalog.pdf} target="_blank" rel="noopener noreferrer nofollow">
                            {/* {currentCatalog.thumb && <img className="promotional_booklet__main_image" src={currentCatalog.thumb} alt={t('niki.b2b.marketing.catalogs')} />} */}
                            {currentCatalog.thumb && <LoadableImage src={currentCatalog.thumb} alt={t('niki.b2b.marketing.catalogs')}/>}
                        </a>
                        <div className={currentCatalog.thumb ? "promotions__two_columns__first__current_booklet" : ["promotions__two_columns__first__current_booklet", "not_catalog"].join(' ')}>
                            {t('niki.b2b.productcatalogs.h1')}
                        </div>
                        <div>{currentCatalog.from} - {currentCatalog.to}</div>
                    </div>
                    <div className="promotions__two_columns__second">
                        <h2>{t('niki.b2b.productcatalogs.full')}</h2>
                        <div className="promotions__two_columns__text">{t('niki.b2b.productcatalogs.downloadfull')}.</div>
                        <a href={currentCatalog.pdf} target="_blank" rel="noopener noreferrer nofollow" className="btn btn-info">{t('niki.b2b.productcatalogs.downloadcurrent')}</a>
                    </div>
                </div>
                <h3 className="all_promotional_booklets">{t('niki.b2b.productcatalogs.previous')}</h3>

                {dataLoaded
                    ? <table className="table">
                        <thead>
                            <tr>
                            <th>{t('niki.b2b.productcatalogs.catalog')}</th>
                                <th>{t('niki.b2b.productcatalogs.edition')}</th>
                                {/* <th>{t('niki.b2b.productcatalogs.season')}</th> */}
                                <th>{t('niki.b2b.product.date')}</th>
                                <th>{t('niki.b2b.process.download')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {catalogsData.length > 0 && catalogsData.map((e,i) =>
                            <tr key={`catalogstr${i}`} className={e.thumb === currentCatalog.thumb ? 'selected_item_in_list' : ''}>
                                <td>{e.thumb && e.pdf && <button onClick={() => setCurrentCatalog(e)}>
                                    {/* href={e.pdf} target="_blank" rel="noopener noreferrer nofollow" */}
                                    {/* <img className="pdf_thumb" src={e.thumb} alt={e.title} loading="lazy" /> */}
                                    <LoadableImage src={e.thumb} alt={e.title} styleImg={{ width: 100}}/>
                                </button>}</td>
                                <td>{e.title}</td>
                                {/* <td>Spring 2022</td> */}
                                <td>{e.from} - {e.to}</td>
                                <td>{e.pdf && <a href={e.pdf} target="_blank" rel="noopener noreferrer nofollow"><Download /></a>}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    : <TablePreloader />
                }
            </div>
        </>
    )
}