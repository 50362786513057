import { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'

import { xCompanyID, searchClient, priceLineID, meilisearchUrl, xTenant } from './config'
import { getRequestOptions, getRequestApi, guruRequestOptions, getCartsData, getMeilisearchIndexName, getBanners, cartHeaderInclude, requestCreateNewCart } from "./helpers/apiHelper"
import { prepareProductToCart } from './helpers/functionsRedux'
import { goToLogin, CheckTokenLifeTime } from './helpers/checkAccessToken'
import { setIsOpenAccountPopup, setIsAccountBlocked, setIsShowMainHeaderSearch, setIsShowHeaderSearch, setIsShowMainSearch, setIsRefreshMarketingCampaigns, setIsReadyFavorites } from './reduxToolkit/universalSlice'
import { setCartHeaders, clearCartContent, setIsCartContentReady, setIsPrepareCartContent } from './reduxToolkit/cartSlice'

import { PriceFormat, RemoveHistoryLocalStorage, ArrayUniqe, translationsForSearchBox, ShowLoader, HideLoader, storingCartData, scrollTop, dateDayDifference, addedCartToShoppingCarts } from './functions'

import { HeaderModals } from './components/header/HeaderModals'
import { TopMenu } from './components/header/TopMenu'
import { RedBanner } from './components/header/RedBanner'
import { SearchResultsHit } from './components/header/SearchResultsHit'

import { InstantSearch, Configure, Hits, SearchBox } from 'react-instantsearch-dom'

import './assets/css/header.css'

import { ReactComponent as HeaderAccount } from './assets/svg/headeraccount.svg'
import { ReactComponent as HeaderOverdue } from './assets/svg/headeroverdue.svg'
import { ReactComponent as HeaderCart } from './assets/svg/headercart.svg'
import { ReactComponent as Search } from './assets/svg/search.svg'
import { ReactComponent as CartIcon } from './assets/svg/greypopupcart.svg'
import { ReactComponent as ArrowRight } from './assets/svg/checkoutwhiterightarrow.svg'
import { ReactComponent as ToUp } from './assets/svg/toUp.svg'

let segmentationInsert = ''
if (localStorage.getItem('account.segmentationids')) segmentationInsert = ArrayUniqe(localStorage.getItem('account.segmentationids').split(',')).join(',')

// todo
const startFacetFilters = [
    // `company_id=${xCompanyID}`,
    // `itemPrices.${priceLineID}.priceline_id=${priceLineID}`,
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    `itemSegmentations IN [${segmentationInsert}]`,
]

const now = new Date()
const todayIs = `${String(now.getDate()).padStart(2, '0')}.${String(now.getMonth() + 1).padStart(2, '0')}.${now.getFullYear()}`

const LoadDebtsData = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/entity-ledgers?search=entity_account_id%3A${localStorage.getItem('account.entity.id.real')}&realKey=1`
    const response = await fetch(URL, getRequestOptions())
    const json = await response.json()
    return json
}

const getCartsDataURL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id')}?realKey=1&sortBy=updated_at`
// const attributesToRetrieve = ['id', 'ItemExtrafieldsAnswers', 'main_unit_code_translations', 'sale_unit_code_translations', 'company_id', 'entity_account_id', 'tax_group_id', 'source_id', 'status_id', 'attachment_id', 'source_item_id', 'source_family_id', 'source_supplier_id', 'source_item_type_id', 'source_barcode_type_id', 'main_unit_code', 'sale_unit_code', 'purchase_unit_code', 'barcode', 'matchcode', 'capacity', 'capacity_unit_code', 'width', 'height', 'length', 'netweight', 'grossweight', 'properties', 'order_apply_round', 'order_round_percent', 'order_round_unit_code', 'family', 'itemStocks', `itemPrices.${priceLineID}`, `itemDiscounts.${priceLineID}`, 'is_booklet', 'is_new', 'itemUnitBarcodeByUnitCodeBox', 'ratings', 'attachment', 'attachments', 'is_discount', 'default_warehouse', 'is_in_stock', 'itemDescriptions', 'brand', 'country', 'kinds'] 
const attributesToRetrieve = ['id', 'main_unit_code_translations', 'source_item_id', 'sale_unit_code', 'matchcode', 'capacity', 'capacity_unit_code', 'itemStocks', `itemPrices.${priceLineID}`, `itemDiscounts.${priceLineID}`, 'itemUnitBarcodeByUnitCodeBox', 'attachment', 'itemDescriptions', 'kinds'] 

export const Header = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const showFixedHeader = 205

    const searchResultsEl = useRef(null)
    const searchResults2El = useRef(null)
    const searchResultsOverlayEl = useRef(null)
    const searchResultsOverlay2El = useRef(null)
    const allResultsEl = useRef(null)
    const allResults2El = useRef(null)

    const [localCartUuid, setLocalCartUuid] = useState(localStorage.getItem('cart.uuid'))
    const [shoppingCartsData, setShoppingCartsData] = useState([])
    const [isGetCart, setIsGetCart] = useState(false)
    const [overdueHeader, setOverdueHeader] = useState('overduehide')
    const [overdueSumm, setOverdueSumm] = useState(0)
    const [isOpenCartPreviewModal, setIsOpenCartPreviewModal] = useState(false)

    const isAccountBlocked = useSelector(state => state.universalContent.isAccountBlocked)
    const isShowMainHeaderSearch = useSelector(state => state.universalContent.isShowMainHeaderSearch)
    const isShowHeaderSearch = useSelector(state => state.universalContent.isShowHeaderSearch)
    const isShowMainSearch = useSelector(state => state.universalContent.isShowMainSearch)

    useEffect(() => {
        localStorage.setItem('current.url', location.pathname)
        let isLogin = false

        if (localStorage.getItem('access.token.lifetime') && localStorage.getItem('access.token') && localStorage.getItem('account.user.name') && localStorage.getItem('account.entity.id') && localStorage.getItem('account.entity.name') && localStorage.getItem('enter.shop')) {
            const TOKEN_LIFETIME = parseInt(localStorage.getItem('access.token.lifetime'))
            isLogin = TOKEN_LIFETIME - parseInt(Date.now()) > 0

            !isLogin && !localStorage.getItem('isCheckTokenLifeTime') && CheckTokenLifeTime(navigate)
        }

        // check Account Blocked
        if (location.pathname === '/debts/' || location.pathname === '/checkout/' || !!localStorage.getItem('isFirstRenderAccount')) {
            !!localStorage.getItem('isFirstRenderAccount') && localStorage.removeItem('isFirstRenderAccount')
            try {
                getRequestApi({ URL: `${process.env.REACT_APP_API_DOMAIN}/b2b/entity-account-check/${localStorage.getItem('account.entity.id')}` })
                .then(response => response.json())
                .then(json => typeof json?.blocked === 'boolean' && dispatch(setIsAccountBlocked(json?.blocked)))
            } catch (error) {
                console.error(error)
            }
        }

        // todo delete after 12 days
        if (!localStorage.getItem('entity_account.priceline_id')) {
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/entity-account/${localStorage.getItem('account.entity.id.real')}?include=company,address,address.contact,user,company.socialValues&realKey=1&type_id=8&itemSegmentations=true`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    localStorage.setItem('entity_account.priceline_id', json.data.priceline_id)
                    getCart(localStorage.getItem('cart.id'))
                }
            })
            .catch(error => console.error(error))
        }
        // eslint-disable-next-line
    }, [location.pathname])

    useEffect(() => {
        window.addEventListener('scroll', isSticky)
        document.body.classList.contains('preload') && document.body.classList.remove('preload')

        if (parseInt(localStorage.getItem('timerUpdateBannerData')) < parseInt(Date.now())) {
            localStorage.setItem('timerUpdateBannerData', new Date().getTime() + 15 * 60 * 1000)

            getRequestApi({ URL: `${process.env.REACT_APP_API_DOMAIN}/b2b/marketplace/data-update-check?updated_at=${localStorage.getItem('updateAtBannersMarketingCampaignIds')}` })
            .then(response => response.json())
            .then(data => {

                if (data?.has_changes) {
                    localStorage.setItem('updateAtBannersMarketingCampaignIds', data.updated_at)
                    
                    const entityID = localStorage.getItem('account.entity.id.real')
                    fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/entity-account/${entityID}?include=realKey=1&type_id=8&itemSegmentations=true`, getRequestOptions())
                        .then(response => response.json())
                        .then(json => {
                            if (JSON.stringify(json.data.marketingCampaignIds) !== localStorage.getItem('marketingCampaignIds')) { 
                                localStorage.setItem('marketingCampaignIds', JSON.stringify(json.data.marketingCampaignIds)
                                    ? JSON.stringify(json.data.marketingCampaignIds)
                                    : '{"new":[],"promo":[],"booklet":[]}'
                                )
                                dispatch(setIsRefreshMarketingCampaigns(true))
                            }
                        })
                        .catch(error => console.error(error))
                    // update banners data
                    getBanners()
                        .then(json => {
                            if (json.data) {
                                localStorage.setItem('banners_data', JSON.stringify(json.data))
                            }
                        })
                        .catch(error => console.error(error))
                }
            })
        }

        return () => {
            window.removeEventListener('scroll', isSticky)
        }
    })

    const isSticky = (e) => {
        const scrollTop = window.scrollY
        const fixed_header = document.querySelector('.fixed_header')
        if (scrollTop >= showFixedHeader) {
            fixed_header.classList.add('active')
            dispatch(setIsShowMainSearch(false))
            isShowMainHeaderSearch && dispatch(setIsShowMainHeaderSearch(false))
            // document.body.classList.add('header_was_fixed')
        } else {
            dispatch(setIsShowMainSearch(true))
            isShowHeaderSearch && dispatch(setIsShowHeaderSearch(false))
            fixed_header.classList.remove('active')
            document.body.classList.remove('header_was_fixed')
        }
    }

    useEffect(() => {
        if (localStorage.getItem('overdue.summ') && parseFloat(localStorage.getItem('overdue.summ')) > 0 && localStorage.getItem('overdue.date') && localStorage.getItem('overdue.date') === todayIs) {
            setOverdueHeader('overdueshow')
            setOverdueSumm(parseFloat(localStorage.getItem('overdue.summ')))
        } else {
            LoadDebtsData()
                .then(json => {
                    if (json.data) {
                        let overdueTotalSumm = 0
                        json.data.forEach(e => {
                            if (dateDayDifference(e.payment_at) < 0) overdueTotalSumm += parseFloat(e.pending_amount)
                        })
                        if (overdueTotalSumm > 0) {
                            setOverdueSumm(overdueTotalSumm)
                            localStorage.setItem('overdue.date', todayIs)
                            localStorage.setItem('overdue.summ', overdueTotalSumm)
                            setOverdueHeader('overdueshow')
                        }
                    }
                })
        }
        
        if (!localStorage.getItem('updateAtBannersMarketingCampaignIds') || localStorage.getItem('updateAtBannersMarketingCampaignIds') === "undefined") { 
            getRequestApi({ URL: `${process.env.REACT_APP_API_DOMAIN}/b2b/marketplace/data-update-check` })
                .then(response => response.json())
                .then(data => {
                    if (data?.updated_at) {
                        localStorage.setItem('updateAtBannersMarketingCampaignIds', data.updated_at)
                    }
                })
        }
        
        localStorage.setItem('timerUpdateBannerData', new Date().getTime() + 15 * 60 * 1000)
    }, [])

    useEffect(() => {
        if (!localStorage.getItem('favorites')) {
            fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("favorites")}/search?filter=user_id=${localStorage.getItem('user.id')}&limit=8000&attributesToRetrieve=favoriteable_id`, guruRequestOptions)
                .then(response => response.json())
                .then(json => {
                    json.hits?.length > 0
                        ? localStorage.setItem('favorites', json.hits.map(i => i.favoriteable_id).join(','))
                        : localStorage.setItem('favorites', '')
                    json.hits?.length > 0 && dispatch(setIsReadyFavorites(true))
                })
                .catch(error => console.error(error))
        }

        if (!localStorage.getItem('history.ids') || localStorage.getItem('history.ids') === "undefined") {
            const attributesToRetrieve = 'attributesToRetrieve=item_id,transaction_uuid,unit_price,quantity,number,created_at'
            fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("doc_details")}/search?filter=account_id=${localStorage.getItem('account.entity.id')}&limit=1000&${attributesToRetrieve}`, guruRequestOptions)
                .then(response => response.json())
                .then(json => {
                    let historyIDs = []
                    RemoveHistoryLocalStorage()
                    json.hits && json.hits.forEach(el => {
                        const productID = el.item_id
                        historyIDs.push(productID)
                        const tempObj = {
                            id: productID,
                            transaction_uuid: el.transaction_uuid,
                            unit_price: el.unit_price,
                            quantity: el.quantity,
                            document_serial_number: el.number,
                            created_at: el.created_at,
                        }
                        localStorage.setItem(`history_${productID}_${el.uuid}`, JSON.stringify(tempObj))
                    })
                    localStorage.setItem('history.ids', historyIDs.join(','))
                    dispatch(setIsRefreshMarketingCampaigns(true))
                })
                .catch(error => console.error(error))
        }
        // eslint-disable-next-line
    }, [])

    const getCart = (cartId) => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-header/${cartId}?include=${cartHeaderInclude.join(',')}&realKey=1&priceline_id=${localStorage.getItem('entity_account.priceline_id')}&priceline_external_id=${priceLineID}&warehouse_external_id=${localStorage.getItem('warehouse.id')}`, getRequestOptions())
            .then(response => {
                if (parseInt(response.status) === 401) goToLogin()
                return response.json()
            })
            .then(json => {
                if (json.data) {
                    dispatch(clearCartContent())
                    dispatch(setCartHeaders({
                        id: json.data.id,
                        idreal: json.data.id_real,
                        name: json.data.matchcode || '',
                        readable_created_at: json.data.readable_created_at,
                    }))
                    if (json?.data?.cartDetails?.data) {
                        json.data.cartDetails.data.forEach((e, index) => {
                            prepareProductToCart(e, dispatch)
                            index === (json.data.cartDetails.data.length - 1) && dispatch(setIsCartContentReady(true))
                        })
                    }
                    dispatch(setIsPrepareCartContent(false))
                } 
            })
            .catch(error => console.error(error))
            .finally(() => {
                HideLoader()
                setIsGetCart(true)
            })
    }

    const setDataCart = (cartData) => {
        storingCartData(cartData.uuid, cartData.id_real, cartData.id)
        const tempObj = addedCartToShoppingCarts(cartData, t)
        setShoppingCartsData(prevState => ([tempObj, ...prevState]))
    }
    const ShowAccountPopup = () => dispatch(setIsOpenAccountPopup(true))

    useEffect(() => {
        try {
            getCartsData(getCartsDataURL)
                .then(json => {
                    if (localCartUuid) {
                        if (Array.isArray(json.data) && json.data.length > 0) {
                            if (json.data.filter(i => i.uuid === localCartUuid).length > 0) {
                                getCart(json.data.filter(i => i.uuid === localCartUuid)[0].id)
                            } else {
                                getCart(json.data[0].id)
                                setDataCart(json.data[0])
                                setLocalCartUuid(json.data[0].uuid)
                            }
                        }
                    } else {
                        let updateDate = 0
                        let res = {}
                        if (json.data && json.data.length > 0) {
                            json.data.forEach(e => {
                                // const date = new Date(e.updated_at).getTime()
                                const date = new Date(e.created_at).getTime()
                                if (updateDate < date) {
                                    updateDate = date
                                    res = e
                                }
                            })
                            getCart(res.id)
                            setDataCart(res)
                        } else {
                            requestCreateNewCart()
                                .then(response => response.json())
                                .then(json => {
                                    if (json.data) {
                                        setDataCart(json.data)
                                    }
                                })
                        }
                    }
                })
        } catch (e) {
            console.error(e.name + ': ' + e.message)
        } finally {
            HideLoader()
        }
        // eslint-disable-next-line
    }, [])

    const ShowHideTopMenu = () => {
        const thisElement = document.querySelector('.fixed_header__menu_button')
        thisElement.classList.toggle('active')
        const topmenu = document.querySelector('.fixed_header .top_menu')
        topmenu.classList.toggle('active')
        document.body.classList.toggle('fixed_menu_showed')
    }

    const ShowCartPopup = e => {
        const ifIsCartInData = () => {
            getCart(shoppingCartsData[0]?.id_hash)
            storingCartData(shoppingCartsData[0].uuid, shoppingCartsData[0].id, shoppingCartsData[0].id_hash)
            setLocalCartUuid(shoppingCartsData[0].uuid)
            setIsOpenCartPreviewModal(true)
        }
        const isOpenPreview = shoppingCartsData.filter(i => i.uuid === localCartUuid).length > 0

        if (localStorage.getItem('cart.id')) {
            setIsOpenCartPreviewModal(true)
        } else {
            if (shoppingCartsData.length > 0 && isOpenPreview) {
                ifIsCartInData()
            } else {
                try {
                    getCartsData(getCartsDataURL)
                        .then(json => {
                            let updateDate = 0
                            let res = {}
                            if (json.data && json.data.length > 0) {
                                json.data.forEach(e => {
                                    // const date = new Date(e.updated_at).getTime()
                                    const date = new Date(e.created_at).getTime()
                                    if (updateDate < date) {
                                        updateDate = date
                                        res = e
                                    }
                                })
                                getCart(res.id)
                                setDataCart(res)
                                setLocalCartUuid(res.uuid)
                            }
                        })
                } catch (e) {
                    console.error(e.name + ': ' + e.message)
                } finally {
                    HideLoader()
                    setIsOpenCartPreviewModal(true)
                }
            }
        }
    }

    const cartNumProducts = useSelector(state => state.cartContent.count)
    const cartSummProducts = useSelector(state => state.cartContent.summ)
    const cartName = useSelector(state => state.cartContent.name)
    const cart = useSelector(state => state.cartContent)
    // todo
    const newCartCreated = localStorage.getItem('create_new_cart')

    useEffect(() => {
        if (newCartCreated && shoppingCartsData.length > 0) {
            const el = JSON.parse(newCartCreated)
            const tempObj = addedCartToShoppingCarts(el, t)
            setShoppingCartsData(prevState => ([tempObj, ...prevState]))
            localStorage.setItem('create_new_cart', '')
        }
        // eslint-disable-next-line
    }, [newCartCreated])

    useEffect(() => {
        setShoppingCartsData(prev => prev.map(i => i.id === cart.idreal ? { ...i, cartname: cart.name } : i))
        // eslint-disable-next-line
    }, [cartName])

    const [widthButtonScroll, setWidthButtonScroll] = useState(78)
    const [showButtonScroll, setShowhButtonScroll] = useState(false)
    const refCartGreyPopup = useRef(null)

    useEffect(() => {
        refCartGreyPopup?.current?.clientWidth && setWidthButtonScroll(refCartGreyPopup.current.clientWidth)
    }, [cartSummProducts])

    function handleScroll() {
        const scrollPosition = window.scrollY;
        scrollPosition > window.outerHeight && setShowhButtonScroll(true)
        scrollPosition < window.outerHeight && setShowhButtonScroll(false)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.addEventListener('scroll', handleScroll);
    }, [])

    useEffect(() => {
        let timer;
        clearTimeout(timer);
        if (isShowMainHeaderSearch || isShowHeaderSearch) {
            timer = setTimeout(() => {
                if (isShowMainHeaderSearch) {
                    !searchResultsEl.current.classList.contains('active') && searchResultsEl.current.classList.add('active')
                    !searchResultsOverlayEl.current.classList.contains('active') && searchResultsOverlayEl.current.classList.add('active')
                } else {
                    !searchResults2El.current.classList.contains('active') && searchResults2El.current.classList.add('active')
                    !searchResultsOverlay2El.current.classList.contains('active') && searchResultsOverlay2El.current.classList.add('active')
                }
                const newInput = document.querySelector('input:not([data-clicked])');
                if (newInput) {
                    newInput.focus()
                }
            }, 100);
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isShowMainHeaderSearch, isShowHeaderSearch])

    const HideSearchResults = () => {
        if (isShowMainHeaderSearch) {
            searchResultsEl.current.classList.contains('active') && searchResultsEl.current.classList.remove('active')
            searchResultsOverlayEl.current.classList.contains('active') && searchResultsOverlayEl.current.classList.remove('active')
            dispatch(setIsShowMainHeaderSearch(false))
        }
        if (isShowHeaderSearch) {
            searchResults2El.current.classList.contains('active') && searchResults2El.current.classList.remove('active')
            searchResultsOverlay2El.current.classList.contains('active') && searchResultsOverlay2El.current.classList.remove('active')
            dispatch(setIsShowHeaderSearch(false))
        }
    }

    return (
        <>
            <header className="header">
                <RedBanner />
                <div className="header_white">
                    <div className={[`header_white__inner wrapper`, overdueHeader].join(' ')}>
                        <div><NavLink className="main_logo" to="/"><img src={`/logo/${xTenant}_${xCompanyID}.svg`} alt="Logo" /></NavLink></div>
                        {isShowMainSearch
                            ? isShowMainHeaderSearch
                                ? <div className={[`search_form`, location.pathname.includes('all-products') ? 'visibilityhidden' : 'visible search_from_main'].join(' ')} method="get">
                                    {!location.pathname.includes('all-products') &&
                                        <InstantSearch indexName={getMeilisearchIndexName("items")} searchClient={searchClient} >
                                            <Configure
                                                facetFilters={startFacetFilters}
                                                hitsPerPage={12}
                                                attributesToHighlight={[]}
                                                attributesToRetrieve={attributesToRetrieve}
                                            />
                                            <SearchBox
                                                onChange={e => allResultsEl.current.href = `/all-products/?search=${e.target.value}`}
                                                translations={translationsForSearchBox()}
                                            />
                                            <div className="search_form__results" ref={searchResultsEl}>
                                                <Hits hitComponent={SearchResultsHit} />
                                                <div className="show_all_results">
                                                    <a href="/all-products/" ref={allResultsEl} className="btn btn-primary">
                                                        {t('niki.b2b.mainmenu.showallresults')}
                                                        <ArrowRight />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="search_form__results__overlay" ref={searchResultsOverlayEl} onClick={HideSearchResults}></div>
                                        </InstantSearch>
                                    }
                                </div>
                                : <div className={[`search_form`, location.pathname.includes('all-products') ? 'visibilityhidden' : 'visible'].join(' ')}>
                                    <input
                                        className='default__header_search_input'
                                        placeholder={t('niki.b2b.header.search_here')}
                                        onClick={() => dispatch(setIsShowMainHeaderSearch(true))}
                                    />
                                    <Search style={{ position: 'absolute', top: '10px', right: '10px' }} />
                                </div>
                            : null
                        }
                        <div className="header_account">
                            <button className="header_button header_account__button" type="button" onClick={ShowAccountPopup}>
                                <HeaderAccount />
                                <span>
                                    <span className="header_account__button__username">{localStorage.getItem('account.entity.name')}</span>
                                    <span>{localStorage.getItem('account.user.name')}</span>
                                </span>
                            </button>
                        </div>
                        <div className={[`header_overdue header__debts_account_blocked_main`, overdueHeader].join(' ')}>
                            <NavLink to="/debts/" className="header_button header_overdue__button" type="button">
                                <HeaderOverdue />
                                <span>
                                    <span className="header_overdue__button__overdue">{t('niki.b2b.header.overdue')}</span>
                                    <span>{PriceFormat(overdueSumm)}</span>
                                </span>

                            </NavLink>
                            {isAccountBlocked && <div>{t('niki.b2b.entity_account_blocked')}</div>}
                        </div>
                        <div className="header_cart">
                            <button className="header_button header_cart__button" type="button" onClick={ShowCartPopup}>
                                <HeaderCart />
                                <span>
                                    <span className="header_cart__amount">{cartNumProducts} {t('niki.b2b.cart.items')}</span>
                                    <span>{PriceFormat(cartSummProducts)}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <TopMenu />
            </header>
            <div className="fixed_header">
                <div className="fixed_header__bg">
                    <div className="fixed_header__wrapper wrapper">
                        <button className="fixed_header__menu_button" type="button" onClick={ShowHideTopMenu}><span></span><span></span><span></span></button>
                        <NavLink to="/" className="fixed_header__logo"><img src={`/logo/${xTenant}_${xCompanyID}.svg`} alt="Logo" /></NavLink>
                        {isShowHeaderSearch
                            ? <div className={[`fixed_header__search_form`, location.pathname.includes('all-products') ? 'visibilityhidden' : 'visible search_from_main'].join(' ')} method="get">
                                {!location.pathname.includes('all-products') &&
                                    <InstantSearch indexName={getMeilisearchIndexName("items")} searchClient={searchClient}>
                                        <Configure
                                            facetFilters={startFacetFilters}
                                            hitsPerPage={12}
                                            attributesToHighlight={[]}
                                            attributesToRetrieve={attributesToRetrieve}
                                        />
                                        <SearchBox
                                            onChange={e => { allResults2El.current.href = `/search/?q=${e.target.value}` }}
                                            translations={translationsForSearchBox()}
                                        />
                                        <div className="search_form__results_2" ref={searchResults2El}>
                                            <Hits hitComponent={SearchResultsHit} />
                                            <div className="show_all_results"><a href="/search/" ref={allResults2El} className="btn btn-primary">{t('niki.b2b.mainmenu.showallresults')}<ArrowRight /></a></div>
                                        </div>
                                        <div className="search_form__results__overlay_2" ref={searchResultsOverlay2El} onClick={HideSearchResults}></div>
                                    </InstantSearch>
                                }
                            </div>
                            : <div className={[`fixed_header__search_form`, location.pathname.includes('all-products') ? 'visibilityhidden' : 'visible'].join(' ')}>
                                <input
                                    className='default__header_search_input'
                                    placeholder={t('niki.b2b.header.search_here')}
                                    onClick={() => dispatch(setIsShowHeaderSearch(true))}
                                />
                                <Search style={{ position: 'absolute', top: '10px', right: '10px' }} />
                            </div>
                        }
                        <div className="fixed_header__icons">
                            <button className="header_button header_account__button header_account__button_small header__button_min_width" onClick={ShowAccountPopup}>
                                <HeaderAccount />
                            </button>
                            <button className={['fixed_header__icons__overdue header__debts_account_blocked', overdueHeader, 'header_button header_cart__button header_info__button_small header__button_min_width'].join(' ')}
                                onClick={() => navigate("/debts/")}
                            >
                                <HeaderOverdue />
                                {true && <div>{t('niki.b2b.entity_account_blocked')}</div>}
                            </button>
                            <button className="header_button header_cart__button header_sum__button_small" type="button" onClick={ShowCartPopup}>
                                <HeaderCart />
                                <span>
                                    <span className="header_cart__amount">{cartNumProducts} {t('niki.b2b.cart.items')}</span>
                                    <span>{PriceFormat(cartSummProducts)}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <TopMenu />
            </div>
            <div className={showButtonScroll ? ["scroll_button_top", "scroll_button_top_active"].join(' ') : "scroll_button_top"}
                style={{ width: widthButtonScroll - 1 }} onClick={scrollTop}>
                <div className="cart_icon_round">
                    <ToUp />
                </div>
                <div className="scroll_button_top_text">{t('niki.b2b.scroll')}</div>
            </div>
            <div className="cart_grey_popup" onClick={ShowCartPopup} ref={refCartGreyPopup}>
                <div className="cart_icon_round">
                    <CartIcon />
                </div>
                <div className="center">
                    <div className="cart_grey_popup__items">{cartNumProducts} {t('niki.b2b.cart.items')}</div>
                    {PriceFormat(cartSummProducts)}
                </div>
            </div>
            <HeaderModals isGetCart={isGetCart} isOpenCartPreviewModal={isOpenCartPreviewModal} setIsOpenCartPreviewModal={setIsOpenCartPreviewModal} />
        </>
    )
}