import { useEffect, useRef, useState } from "react"
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { t } from "i18next"

import { getRequestOptions, getRequestApi } from '../helpers/apiHelper'
import { setIsMore5CartsAlert } from '../reduxToolkit/universalSlice'
import { PriceFormat, VatByCartDetails, NetByCartDetails, ShowLoader, HideLoader, storingCartData, FormatDate } from "../functions"

import { Breadcrumbs } from "../breadcrumbs"
import { TablePreloader } from "../components/TablePreloader"
import { ModalDeleteCart } from "../components/ModalDeleteCart"
import { CreateNewCartModal } from "../components/CreateNewCartModal"

import { ReactComponent as Duplicate } from './../assets/svg/shoppingcartsduplicate.svg'
import { ReactComponent as Open } from './../assets/svg/shoppingcartsopen.svg'
import { ReactComponent as Delete } from './../assets/svg/shoppingcartsdelete.svg'

const LoadCartsData = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id')}?include=status,company,cartDetails,items&realKey=1&orderBy=id&sortedBy=desc&showVat=true`
    const response = await fetch(URL,getRequestOptions())
    const json = response.json()
    return json
}

const requestDuplicateCart = cartItemID => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/duplicate-cart-headers/${cartItemID}?include=cartDetails&realKey=1`
    const response = getRequestApi({URL})
    return response
}

export const ShoppingCarts = () => {

    const dispatch = useDispatch()

    const cartPopupEl = useRef(null)

    const [dataLoaded, setDataLoaded] = useState(false)
    const [tableData, setTableData] = useState([])
    const [cartID, setCartID] = useState('')
    const [cartIDReal, setCartIDReal] = useState('')
    const [skus, setSkus] = useState(0)
    const [gros, setGros] = useState(0)
    const [disable, setDisable] = useState(false)
    const [isOpenCreateNewCartModal, setIsOpenCreateNewCartModal] = useState(false) 
    
    useEffect(()=>{
        ShowLoader()
        LoadCartsData()
        .then(json => {
            setTableData([])
            json.data && json.data.forEach(e => {
                let summ = 0
                if (e.cartDetails && e.cartDetails.data && e.cartDetails.data.length > 0) {
                    e.cartDetails.data.forEach(el => summ += parseInt(el.qty)*parseFloat(el.unit_price))
                }
                const cartVat = (e.cartDetails && e.cartDetails.data && e.cartDetails.data.length > 0) ? VatByCartDetails(e.cartDetails.data) : 0
                const cartNet = (e.cartDetails && e.cartDetails.data && e.cartDetails.data.length > 0) ? NetByCartDetails(e.cartDetails.data) : 0
                const tempObj = {
                    id: e.id,
                    idReal: e.id_real,
                    uuid: e.uuid,
                    name: e.matchcode,
                    date: e.created_at,
                    skus: (e.cartDetails && e.cartDetails.data && e.cartDetails.data.length) || 0,
                    gros: cartNet+cartVat,
                    vat: cartVat,
                    net: cartNet,
                }
                setTableData(prevState => ([...prevState, tempObj]))
            })
            setDataLoaded(true)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }, [])
    
    const DeleteCart = e => {
        setCartID(e.target.dataset.id)
        setCartIDReal(e.target.dataset.idreal)
        setSkus(e.target.dataset.skus)
        setGros(e.target.dataset.gros)
        cartPopupEl.current.classList.add('active')
    }

    const ClosePopup = () => cartPopupEl.current.classList.remove('active')
    const ConfirmDelete = (deleteID) => setTableData(current => current.filter(item => item.id !== deleteID))
    const openShoppingCart = val => localStorage.setItem('cart_data', `${val.uuid},${val.idReal},${val.id}`)
    const openPopupEnterCartName = () => setIsOpenCreateNewCartModal(true)

    const OpenCart = e => {
        const val = e.target.dataset
        storingCartData(val.uuid, val.idreal, val.id)
        window.location.href = '/myshoppingcart/'
    }

    const DuplicateCart = e => {
        setDisable(true)
        ShowLoader()
        requestDuplicateCart(e.target.dataset.id)
            .then(response => response.json())
            .then(json => {
                if (json.message && !json.message.includes("authenticate")) {
                   dispatch(setIsMore5CartsAlert(true))
                } else if (json.data) {
                    const tempObj = {
                        id: json.data.id,
                        idReal: json.data.real_id,
                        uuid: json.data.uuid,
                        date: json.data.created_at,
                        skus: e.target.dataset.skus,
                        gros: e.target.dataset.gros,
                        vat: e.target.dataset.vat,
                        net: e.target.dataset.net,
                    }
                    setTableData([tempObj,...tableData])
                }
            })
            .catch(error => console.error(error))
            .finally(() => {
                setDisable(false)
                HideLoader()
            })
    }

    return (
        <>
            <Breadcrumbs itemlist={[{ 'url': '/account/', 'title': t('niki.b2b.menu.myaccount') }, { 'url': '/shoppingcarts/', 'title': t('niki.b2b.cart.shoppingcarts') }]} />
            <div className="wrapper">
                <div className="wrapper__title_carts">
                    <h1>{t('niki.b2b.cart.myshoppingcarts')}</h1>
                    <button className="btn btn-primary" onClick={openPopupEnterCartName}>{t('niki.b2b.cartflow.createnewcart')}</button>
                </div>
                
                {dataLoaded
                    ? <table className="table">
                        <thead>
                            <tr>
                                <th>{t('niki.b2b.cart.shoppingcart')}</th>
                                <th>{t('niki.b2b.cartflow.cartname')}</th>
                                <th>{t('niki.b2b.shoppingcart.creationdate')}</th>
                                <th>{t('niki.b2b.debts.skus')}</th>
                                <th>{t('niki.b2b.product.net')}</th>
                                <th>{t('niki.b2b.product.vat')}</th>
                                <th>{t('niki.b2b.product.gross')}</th>                      
                                <th>{t('niki.b2b.orders.duplicate')}</th>
                                <th>{t('niki.b2b.shoppingcarts.continue')}</th>
                                <th>{t('niki.b2b.shoppingcart.delete')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {tableData.map((e,i)=>
                            <tr key={`tr${i}`}>
                                <td><NavLink to={`/shoppingcart/${e.id}/`} onClick={() => openShoppingCart(e)}>{e.idReal}</NavLink></td>
                                <td>{e.name || t('niki.b2b.cart.shoppingcart')}</td>
                                <td>{FormatDate(e.date)}</td>
                                <td>{e.skus}</td>
                                <td className="right">{PriceFormat(e.net)}</td>
                                <td className="right">{PriceFormat(e.vat)}</td>
                                <td className="right">{PriceFormat(e.gros)}</td>
                                <td>
                                    <button
                                        disabled={disable}
                                        className="table_button"
                                        data-id={e.id}
                                        data-skus={e.skus}
                                        data-vat={e.vat}
                                        data-net={e.net}
                                        data-gros={e.gros}
                                        onClick={DuplicateCart}
                                    ><Duplicate />
                                    </button>
                                </td>
                                <td>
                                    <button
                                        disabled={disable}
                                        className="table_button"
                                        data-id={e.id}
                                        data-idreal={e.idReal}
                                        data-uuid={e.uuid}
                                        onClick={OpenCart}
                                    ><Open /></button>
                                </td>
                                <td>
                                    <button
                                        disabled={disable}
                                        className="table_button"
                                        data-id={e.id}
                                        data-idreal={e.idReal}
                                        data-skus={e.skus}
                                        data-gros={e.gros}
                                        onClick={DeleteCart}
                                    ><Delete />
                                    </button>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    : <TablePreloader rows={'1'.repeat(4)} columns={'1'.repeat(9)}/>
                }
                <ModalDeleteCart refEl={cartPopupEl} ClosePopup={ClosePopup} cartIDReal={cartIDReal} skus={skus} gros={gros}
                    cartID={cartID} ConfirmDelete={ConfirmDelete}
                />
                {isOpenCreateNewCartModal && <CreateNewCartModal close={() => setIsOpenCreateNewCartModal(false)} />}
            </div>
        </>
    )
}