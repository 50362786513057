import { useEffect, useState } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import { priceLineID } from "../config"
import { ShowLoader, HideLoader } from "../functions"
import { getRequestOptions, getOpenPDF, getCartsData } from '../helpers/apiHelper'

import { Breadcrumbs } from "../breadcrumbs"
import { ContactsAside } from "../components/contactsaside"
import LoadableImage from "../components/LoadableImage/LoadableImage"

import { ReactComponent as Account } from './../assets/svg/gridaccount.svg'
import { ReactComponent as Orders } from './../assets/svg/gridorders.svg'
import { ReactComponent as ShoppingCarts } from './../assets/svg/gridshoppingcarts.svg'
import { ReactComponent as Printer } from './../assets/svg/feedbackprint.svg'

const ReturnRegisteredContent = props => {

    const dispatch = useDispatch()

    const returnID = props.returnID
    const [returnIDReal, setReturnIDReal] = useState(null)
    const [keySerial, setKeySerial] = useState(null)
    const [numberCarts, setNumberCarts] = useState(0)

    useEffect(() => {
        getCartsData().then(json => json?.data && setNumberCarts(json.data.length))
    }, [])

    useEffect(() => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents/${returnID}?include=details,status&realKey=true&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                json.data.id_real && setReturnIDReal(json.data.number)
                json.data && setKeySerial(`${json.data?.key} ${json.data?.serial} - `)
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }, [returnID])

    return (
        <div className="wrapper">
            <h1>{t('niki.b2b.returnproducts.registered')}</h1>
            <div className="return_registered__columns">
                <div>
                    {(keySerial && returnIDReal)
                        ? <div className="return_registered__red">
                            <h2>{t('niki.b2b.returnproducts.returnreta')} {keySerial} {returnIDReal}</h2>
                            <div>{t('niki.b2b.returnproducts.redtitle')}.<br />
                                {t('niki.b2b.returnproducts.redtext')}</div>
                            <button
                                className="return_registered__red__button"
                                data-uuid={returnID}
                                onClick={() => getOpenPDF(returnID, dispatch)}
                            ><Printer />{t('niki.b2b.feedback.printreceipt')}</button>
                        </div>
                        : <div className="return_registered__red" style={{ height: '243px' }}>
                            <LoadableImage src={'src'} alt={'title'} height={'43px'} margin={'0 0 20px 0'} />
                            <div>{t('niki.b2b.returnproducts.loadingdocument')}</div>
                        </div>
                    }

                    <h2 className="marginbottom40">{t('niki.b2b.feedback.otheroptons')}</h2>
                    <ul className="return_registered__grid">
                        <li><NavLink to="/account/"><Account />{t('niki.b2b.menu.myaccount')}</NavLink></li>
                        <li><NavLink to="/orders/"><Orders />{t('niki.b2b.account.orders')}</NavLink></li>
                        <li><NavLink to="/shoppingcarts/"><ShoppingCarts />{t('niki.b2b.cart.shoppingcarts')} {numberCarts > 0 && <span className="grid_alert">{numberCarts}</span>}</NavLink></li>
                    </ul>
                </div>
                <div>
                    <div className="your_designated_support_team no_top_margin">{t('niki.b2b.account.supportteam')}</div>
                    <ContactsAside />
                </div>
            </div>
        </div>
    )
}

export const ReturnRegistered = () => {
    const { id } = useParams()
    return (
        <>
            <div className="breadcrumbs_on_2_colummns">
                <Breadcrumbs itemlist={[
                    { 'url': '/account/', 'title': t('niki.b2b.menu.myaccount') },
                    { 'url': '/Invoices/', 'title': t('niki.b2b.menu.myinvoices') },
                    { 'url': `/returnregistered/${id}/`, 'title': t('niki.b2b.returnproducts.complete') }
                ]} />
                <ol className="cart_steps">
                    <li>{t('niki.b2b.returnproducts.products')}</li>
                    <li>{t('niki.b2b.returnproducts.quantities')}</li>
                    <li className="current">{t('niki.b2b.feedback.confirmation')}</li>
                </ol>
            </div>
            <ReturnRegisteredContent returnID={id} />
        </>
    )
}