import { useEffect, useRef, useState } from "react"
import { languages, languagesArray } from "../config"
import { FixCompanyName, FetchPreferencesPost, ShowLoader, HideLoader, returnLangForFlag } from "../functions"
import { getCartsData, loadDebtsData } from "../helpers/apiHelper"
import { NavLink } from 'react-router-dom'

import { Breadcrumbs } from "../breadcrumbs"
import { ContactsAside } from "../components/contactsaside"

import "./../assets/css/staticpages.css"

import { ReactComponent as Delivery } from './../assets/svg/myaccountdelivery.svg'
import { ReactComponent as Invoices } from './../assets/svg/myaccountinvoices.svg'
import { ReactComponent as Debts } from './../assets/svg/myaccountdebts.svg'
import { ReactComponent as Orders } from './../assets/svg/myaccountorders.svg'
import { ReactComponent as ShoppingCarts } from './../assets/svg/myaccountshoppingcarts.svg'
import { ReactComponent as Favorites } from './../assets/svg/myaccountfavorites.svg'
import { ReactComponent as Marketing } from './../assets/svg/myaccountmarketing.svg'
import { ReactComponent as Feedback } from './../assets/svg/myaccountfeedback.svg'
import { t } from "i18next"


const lang = localStorage.getItem('locale')

export const Account = () => {

    const LangSelectorEl = useRef(null)
    const accountLangSelectorEl = useRef(null)

    const [numberCarts,setNumberCarts] = useState(0)
    const [numberDebts,setNumberDebts] = useState(0)
    
    useEffect(() => {
        getCartsData().then(json => json?.data && setNumberCarts(json.data.length))
        loadDebtsData().then(json => json?.data && setNumberDebts(json.data.length))
    },[])

    const ShowLangSelector = () => {
        accountLangSelectorEl.current.classList.add('active')
    }
    const HideLangSelector = () => {
        accountLangSelectorEl.current.classList.remove('active')
    }

    const SelectLang = e => {
        LangSelectorEl.current.classList.remove('active')
        localStorage.setItem('locale',e.target.dataset.flag)
        localStorage.setItem('locale.full',e.target.dataset.country)
        ShowLoader()
        FetchPreferencesPost(e.target.dataset.flag)
        .catch(error => console.error(error))
        .finally(() => {
            document.location.reload()
            HideLoader()
        })
        document.location.reload()
    }

    return (
        <>
            <Breadcrumbs itemlist={[{'url':'/account/','title':t('niki.b2b.account.my')}]} />
            <div className="wrapper">
                <h1 style={{paddingBottom: 15}}>{t('niki.b2b.account.my')}</h1>
                <div className="myaccount__hello">{t('niki.b2b.account.hello')}, {localStorage.getItem('account.user.name')}</div>
                <div>{FixCompanyName(t('niki.b2b.account.welcome'))}</div>
                <div className="myaccount__two_columns">
                    <div>
                        <ul className="myaccount__buttons">
                            <li><NavLink to="/deliveryaddresses/" className="myaccount__button"><Delivery />{t('niki.b2b.account.deliveryaddresses')}</NavLink></li>
                            <li><NavLink to="/invoices/" className="myaccount__button"><Invoices />{t('niki.b2b.menu.invoicesreturns')}</NavLink></li>
                            <li><NavLink to="/debts/" className="myaccount__button"><Debts />{t('niki.b2b.account.debts')} {numberDebts > 0 && <span className="myaccount__buttons__alert">{numberDebts}</span>}</NavLink></li>
                            <li><NavLink to="/orders/" className="myaccount__button"><Orders />{t('niki.b2b.account.orders')}</NavLink></li>
                            <li><NavLink to="/shoppingcarts/" className="myaccount__button myaccount__button__showppingcarts"><ShoppingCarts />{t('niki.b2b.cart.shoppingcarts')} {numberCarts > 0 && <span className="myaccount__buttons__alert">{numberCarts}</span>}</NavLink></li>
                            <li>
                                <button onClick={ShowLangSelector} className="myaccount__button myaccount__button__lang" type="button">
                                    <span className={`myaccount__buttons__language flag-icon-${returnLangForFlag(localStorage.getItem('locale'))}`}></span>
                                    {t('niki.b2b.account.language')}
                                    <span className="myaccount__buttons__small">
                                        {t(languagesArray[lang])}
                                    </span>
                                </button>
                            </li>
                            <li><NavLink to="/all-products/?show=favorites" className="myaccount__button"><Favorites />{t('niki.b2b.menu.favorites')}</NavLink></li>
                            <li><NavLink to="/marketing/" className="myaccount__button marketing"><Marketing />{t('niki.b2b.menu.marketing')}</NavLink></li>
                            <li><NavLink to="/feedback/" className="myaccount__button feedback"><Feedback />{t('niki.b2b.menu.feedback')}</NavLink></li>
                        </ul>
                    </div>
                    <div className="contacts__support">
                        <div className="contacts__support__header">{t('niki.b2b.account.supportteam')}</div>
                        <ContactsAside />
                    </div>
                </div>
            </div>
            <div className="account_lang_selector__wrapper" ref={accountLangSelectorEl}>
                <ul className="login_form__lang_selector__wrapper active" ref={LangSelectorEl}>
                    {languages.map((item,key)=>
                        <li key={key} className={[`lang_${item.flag}`,localStorage.getItem('locale') === item.flag ? 'active' : null].join(' ')} data-flag={item.flag} data-country={item.country} onClick={SelectLang}>
                            <span className={`login_form__flag flag-icon-squared flag-icon-${item.flag}`}></span>
                            {t(item.translateCountry)}
                        </li>
                    )}
                </ul>
                <div className="account_lang_selector__overlay" onClick={HideLangSelector}></div>
            </div>
        </>
    )
}