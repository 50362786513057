import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { t } from 'i18next'
import { useDispatch, useSelector } from "react-redux"
import { isDropshippingCompany } from "../../config"
import { PriceFormat } from '../../functions'
import { setIsProductSearch, setShoppingCartSummaryCleare } from "../../reduxToolkit/cartSlice"

import "../../assets/css/cart.css"

import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right-white.svg'

export const CartAside = ({supplierTypes, isOpenDropshipping, setIsOpenDropshipping}) => {

    const dispatch = useDispatch()

    const cartSumm = parseFloat(useSelector(state => state.cartContent.summ))
    const cartSummOld = parseFloat(useSelector(state => state.cartContent.summold))
    const cartSummVat = parseFloat(useSelector(state => state.cartContent.vat))
    const cartNumProducts = useSelector(state => state.cartContent.count)
    const shoppingCartSummary = useSelector(state => state.cartContent.shoppingCartSummary) || []
    
    useEffect(() => {
        dispatch(setIsProductSearch(false))
        // eslint-disable-next-line
    }, []);
    
    const onCheckout = () => {
        if (supplierTypes.filter(i => i !== undefined).length > 1 && !isOpenDropshipping) {
            setIsOpenDropshipping(true)
            dispatch(setShoppingCartSummaryCleare())
            dispatch(setIsProductSearch(true))
        } else {
            localStorage.setItem('suppliers_data', JSON.stringify(shoppingCartSummary))
            // window.location.href = '/checkout/'
        }
    }

    return (
        <div className="cart_aside">
            <div className="cart_aside__inner">
                {shoppingCartSummary.length > 0 && isDropshippingCompany
                    ? <>
                        <div className="cart_aside__header">{t('niki.b2b.shoppingcart.summary')}</div>
                        {shoppingCartSummary.map((s, i) => {
                            return (
                                <div className="shopping_cart_summary" key={i}>
                                    <div>
                                        {t('niki.b2b.feedback.supplier')}: {s.supplierName.includes('Monolith') ? '' : t('niki.b2b.cart.partner')} {s.supplierName}
                                    </div>
                                    <div className="cart_aside__table">
                                        <div>{t('niki.b2b.orders.totalskus')}</div>
                                        <div>{s.count}</div>
                                    </div>
                                    <div className="cart_aside__table">
                                        <div>{t('niki.b2b.cart.total')}</div>
                                        <div>{s.total}</div>
                                    </div>
                                    <hr />
                                </div>
                            )
                        })} 
                    </>
                    : <>
                        <div className="cart_aside__header">{t('niki.b2b.shoppingcart.summary')}</div>
                        <div className="cart_aside__table">
                        <div>{t('niki.b2b.orders.totalskus')}</div>
                            <div>{cartNumProducts}</div>
                        </div>
                        <div className="cart_aside__table">
                            <div>{t('niki.b2b.orders.totalnet')}</div>
                            <div>{PriceFormat(cartSumm)}</div>
                        </div>
                        <div className="cart_aside__table">
                            <div>{t('niki.b2b.orders.totalvat')}</div>
                            <div>{PriceFormat(cartSummVat)}</div>
                        </div>
                    </>
                }
                
                {cartSumm !== cartSummOld &&
                    <>
                        {shoppingCartSummary.length === 0 && <hr />}
                        <div className="cart_aside__table red">
                            <div>{t('niki.b2b.shoppingcart.totalsavings')}</div>
                            <div>{PriceFormat(cartSummOld - cartSumm)}</div>
                        </div>
                    </>
                }
                {cartSumm !== cartSummOld && <hr />}
                <div className="cart_aside__table">
                    <div>{t('niki.b2b.orders.totalgros')}</div>
                    <div className="cart_aside__table__big_price">{PriceFormat(cartSumm + cartSummVat)}</div>
                </div>
                {isOpenDropshipping || supplierTypes.length === 1
                    ? <NavLink to={'/checkout/'} className="btn btn-block btn-primary checkout_now" onClick={onCheckout}>{t('niki.b2b.cartflow.checkout')} <ArrowRight /></NavLink>
                    : <button className="btn btn-block btn-primary checkout_now" onClick={onCheckout}>{t('niki.b2b.cartflow.checkout')} <ArrowRight /></button>
                }
            </div>
        </div>
    )
}