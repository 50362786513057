import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { xCompanyID, searchClient, priceLineID, pricelineIDRecommended, meilisearchUrl } from '../config'
import { getRequestOptions, guruRequestOptions, returnErrorDuringAddProductToCart, getMeilisearchIndexName } from "../helpers/apiHelper"
import { prepareProductToCart } from '../helpers/functionsRedux'
import { PriceFormat, CapacityString, FormatDate, ShowLoader, HideLoader, ArrayUniqe, GetHistoryData, CalculateRating, GetGlobalBannerData, LangFix, returnItemExtrafields, storingCartData, onBannerView, checkValueOrNonInteger } from '../functions'

import { updateCartProduct, deleteCartProduct } from '../reduxToolkit/cartSlice'

import { InstantSearch, Configure, Hits } from 'react-instantsearch-dom'

import { Product } from './product/Product'
import { ProdHistoryPopup } from './ProdHistoryPopup'
import { setIsOpenProductNotFoundAlert } from '../reduxToolkit/universalSlice'
import { ProductPagePlaceholder } from './productPage/ProductPagePlaceholder'
import { ProductIngredientsNutrients } from './productPage/ProductIngredientsNutrients'
import InFavorite from './product/InFavorite'
import LoadableImage from "./LoadableImage/LoadableImage"

import { ReactComponent as TruckSmall } from '../assets/svg/checkoutTruckSmall.svg'
import { ReactComponent as ArrowGreenTopRight } from '../assets/svg/arrowgreentopright.svg'
import { ReactComponent as ArrowRedBottomRight } from '../assets/svg/arrowredbottomright.svg'
import { ReactComponent as CartPlusBig } from '../assets/svg/cartplusbig.svg'
import { ReactComponent as TradeMark } from '../assets/svg/r.svg'
import { ReactComponent as ShelfLife } from '../assets/svg/shelflife.svg'
import { ReactComponent as Ref } from '../assets/svg/ref.svg'
import { ReactComponent as Barcode } from '../assets/svg/barcode.svg'
import { ReactComponent as Book } from '../assets/svg/book.svg'
import { ReactComponent as New } from '../assets/svg/new.svg'
import { ReactComponent as Box } from '../assets/svg/box.svg'
import { ReactComponent as Trash } from '../assets/svg/trash.svg'
import { ReactComponent as StarEmpty } from '../assets/svg/starempty.svg'
import { ReactComponent as Star } from '../assets/svg/star.svg'
import { ReactComponent as InfoWhite } from '../assets/svg/infowhite.svg'

import defaultImage from '../assets/images/placeholder.jpg'

let segmentationInsert = ''
if (localStorage.getItem('account.segmentationids')) segmentationInsert = ArrayUniqe(localStorage.getItem('account.segmentationids').split(',')).join(',')

const startFacetFilters = [
                            `company_id=${xCompanyID}`,
                            `itemPrices.${priceLineID}.priceline_id=${priceLineID}`,
                            `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
                            `itemSegmentations IN [${segmentationInsert}]`
]

const GetVoteData = async productID => {
    const fetchURL = `${process.env.REACT_APP_API_DOMAIN}/b2b/items/${productID}/rating-info`
    const response = await fetch(fetchURL,getRequestOptions())
    const json = await response.json()
    return json
}

export const ModalProductPage = (props) => {   

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const productsInCart = useSelector(state => state.cartContent.productIDs)
    const productQTYs = useSelector(state => state.cartContent.productQTYs)
    const cartDetailsIDs = useSelector(state => state.cartContent.cartDetailsIDs)
    const selectedLanguage = LangFix(localStorage.getItem('locale'))

    const [productID, setProductID] = useState(props.productID || '')
    const [product, setProductData] = useState(null)
    const [cartButtonStatus, setCartButtonStatus] = useState('normal')
    const [isLoaded, setIsLoaded] = useState(true)
    const [currentImage, setCurrentImage] = useState()
    const [productItemID, setProductItemID] = useState('')
    const [ratingStatus, setRatingStatus] = useState('waiting')
    const [ratingData, setRatingData] = useState({rating: 0, ratingVoices: 0})
    const [similarFacetFilters, setSimilarFacetFilters] = useState(startFacetFilters)
    const [bannersMiddle, setBannersMiddle] = useState([])
    const [cartAmount, setCartAmount] = useState(productQTYs[productID] || 0)
    const [isChangedInput, setChangedInput] = useState(false)
    
    const ratingPopupEl = useRef(null)
    const thisOverlayEl = useRef(null)
    
    const urlapi = `${process.env.REACT_APP_API_DOMAIN}/b2b/item-list`
    let url = new URL(urlapi)
    const params = [
        ['priceline_id[]', priceLineID],
        ['include', 'itemImages,itemStocks,saleUnit,country,itemKinds,itemBrands,itemDiscounts,ingredients,ingredientNutriens,itemRecommendedPrices,itemHistories,itemDescriptions'],
        ['warehouse_id', localStorage.getItem('warehouse.id')],
        ['limit', '1'],
        ['page', '1'],
        ['id', productID],
        ['entity_account_id',localStorage.getItem('account.entity.id')],
        ['rand',Math.random()]
    ]
    url.search = new URLSearchParams(params).toString()

    const fetchProductData = async () => {
        const filterInsert = `id=${productID}`
        const attributesToRetrieve = `attributesToRetrieve","value":"id,company_id,entity_account_id,tax_group_id,source_id,status_id,attachment_id,source_item_id,source_family_id,source_supplier_id,source_item_type_id,source_barcode_type_id,main_unit_code,sale_unit_code,purchase_unit_code,barcode,matchcode,capacity,capacity_unit_code,width,height,length,netweight,grossweight,properties,order_apply_round,order_round_percent,order_round_unit_code,family,itemStocks,itemPrices.${priceLineID},is_booklet,favoriteable_id,is_new,itemUnitBarcodeByUnitCodeBox,ratings,attachment,attachments,is_discount,default_warehouse,is_in_stock,itemDescriptions,ingredients,ingredientNutrients,itemRecommendedPrices.${pricelineIDRecommended},itemDiscounts.${priceLineID},kinds,country,id,${xCompanyID === '4' ? 'brand' : 'trademark' },ItemExtrafieldsAnswers,sale_unit_code_translations`
        const response = await fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("items")}/search?filter=${encodeURIComponent(filterInsert)}&attributesToRetrieve=${encodeURIComponent(attributesToRetrieve)}`, guruRequestOptions)
        const json = await response.json()
        return json
    }
    const startLoading = () => {
        setCartButtonStatus('waiting')
        ShowLoader()
    }
    const finallyRequest = () => {
        HideLoader()
        setCartButtonStatus('normal')
    }

    useEffect(() => {
        thisOverlayEl.current.classList.add('active')   
        const data = JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            e.content_subtype_id === 'gOkj31nzQol9e5EQ' && e.contentType?.data?.real_id === 6 && setBannersMiddle(GetGlobalBannerData(e))
        })

        const body = document.body;
        const originalStyle = body.style.overflow;
        body.style.overflow = 'hidden';

        return (() => {
            body.style.overflow = originalStyle
        })
    }, [])

    useEffect(() => {
        setProductID(props.productID)
    }, [props.productID])

    const returnError = (error) => {
        dispatch(setIsOpenProductNotFoundAlert(true))
        console.error(error)
        setCartAmount(productQTYs[productID])
    }

    useEffect(() => {
        productQTYs[productID] && setCartAmount(productQTYs[productID])
    }, [productQTYs, productID])

    const RemoveCartItem = () => {
        const cartDetail = cartDetailsIDs[productID]
        startLoading()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${cartDetail}`, getRequestOptions('DELETE'))
        .then(response => {
            if (response.ok) {
                dispatch(deleteCartProduct(cartDetail))
            }
        })
        .catch(error => returnError(error))
        .finally(() => finallyRequest())
    }

    const UdpdateCartNum = (value) => {
        const localValue = typeof value === "string" ? parseFloat(value) : value
        if (localValue === 0) {
            RemoveCartItem()
            return
        }
        const cartDetail = cartDetailsIDs[productID]
        startLoading()
        const requestOptions = getRequestOptions('POST', null, 'application/json')
        requestOptions.body = JSON.stringify({ qty: localValue })

        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${cartDetail}`, requestOptions)
            .then(response => {
                if (response.ok) { 
                    dispatch(updateCartProduct({ productid: productID, cartdetail: cartDetail, qty: localValue }))
                } else {
                    dispatch(setIsOpenProductNotFoundAlert(true))
                    setCartAmount(productQTYs[productID])
                }
        })
        .catch(error => returnError(error))
        .finally(() => finallyRequest())
    }
    
    const CartInputChange = e => {
        const value = e.target.value
        typeof value === "string" && !value && setCartAmount('')
        
        const localValue = checkValueOrNonInteger(value, product.saleUnitCode)
        if (localValue === '') return 

        setCartAmount(localValue)
        setChangedInput(true)
    }

    useEffect(() => {
        let timer;
        if (isChangedInput) {
            if (cartAmount) {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    setChangedInput(false)
                    UdpdateCartNum(cartAmount)
                }, 1150);
            }    
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [cartAmount]);

    const PlusCart = () => {
        const sum = product.multiplier + productQTYs[productID]
        const resultSum = typeof sum === "string" ? parseFloat(sum) : sum
        const resPlus = Number.isInteger(resultSum) ? resultSum : resultSum.toFixed(2)
        
        setCartAmount(resPlus)
        UdpdateCartNum(resPlus)
    }

    const MinusCart = () => {
        const subtractionResult = productQTYs[productID] - product.multiplier < product.multiplier ? product.multiplier : productQTYs[productID] - product.multiplier
        const difference = Number.isInteger(subtractionResult) ? subtractionResult : subtractionResult.toFixed(2)

        setCartAmount(difference)
        UdpdateCartNum(difference)
    }

    const PutToCart = () => {
        startLoading()
        if (localStorage.getItem('cart.uuid')) {
            const uuid = localStorage.getItem('cart.uuid')
            const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
            requestOptionsCart.body = JSON.stringify({
                cart_header_uuid: uuid,
                item_id: productID,
                matchcode: product.name,
                variation_id: '3',
                qty_in_box: product.multiplier.toString(),
                qty: product.multiplier.toString(),
                unit_price: product.unitPrice.toString(),
                tax_included_price: product.gross.toString()
            })
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details?include=item,item.attachments,item.entityAccount`,requestOptionsCart)
            .then(response => response.json())
                .then(json => {
                    if (json?.message) {
                        returnErrorDuringAddProductToCart(json.message, dispatch)
                    } else if (json.data) {
                        prepareProductToCart(json.data, dispatch)
                    }
                setCartButtonStatus('normal')
            })
            .catch(error => returnError(error))
            .finally(() => finallyRequest())
        } else {
            let detaildata = []
            detaildata[0] = {
                matchcode: product.name,
                variation_id: '1',
                qty: product.multiplier.toString(),
                unit_price: product.unitPrice.toString(),
                tax_included_price: product.gross.toString(),
                qty_in_box: product.multiplier.toString(),
                item_id: productID
            }
            const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
            requestOptionsCart.body = JSON.stringify({
                company_id: product.companyId,
                customer_account_id: localStorage.getItem('account.entity.id'),
                // customer_account_id: 2,
                status_id: 'J8RvMwowm7k2pNOr',
                details: detaildata
            })
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers`,requestOptionsCart)
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    storingCartData(json.data.uuid, json.data.id_real, json.data.id)
                    prepareProductToCart(json.data, dispatch)
                }
                setCartButtonStatus('normal')
            })
            .catch(error => returnError(error))
            .finally(() => finallyRequest())
        }
    }

    useEffect(() => {
        ShowLoader()
        fetchProductData().then(json => {
            const data = json.hits.filter(e => e.id === productID)[0]
            let productImagesArray = []
            let productIcons = []
            let productHistory = []
            let isShowStock = true
            let isShowStockText = true
            let stockText = t('niki.b2b.product.outofstock')
            let priceTemp = data.itemPrices[priceLineID]?.unit_price || 0
            let grossTemp = data.itemPrices[priceLineID]?.tax_included_price || 0
            let oldPrice = 0
            let grossOld = 0
            let vatTemp = grossTemp - priceTemp
            let vatOld
            if (data.itemDiscounts && data.itemDiscounts[priceLineID]) {
                oldPrice = priceTemp
                priceTemp = data.itemDiscounts[priceLineID].unit_price
                grossOld = grossTemp
                grossTemp = data.itemDiscounts[priceLineID].tax_included_price
                vatOld = vatTemp
                vatTemp = grossTemp - priceTemp
            }
            const rrpJson = (data.itemRecommendedPrices && data.itemRecommendedPrices[pricelineIDRecommended] && data.itemRecommendedPrices[pricelineIDRecommended].tax_included_price && parseFloat(data.itemRecommendedPrices[pricelineIDRecommended].tax_included_price)) || 0

            if (data.attachments && data.attachments.length > 0) {
                data.attachments.forEach(el => productImagesArray.push(el))
            }
            if (data.ItemExtrafieldsAnswers) {
                productIcons.push(returnItemExtrafields(data.ItemExtrafieldsAnswers) ? returnItemExtrafields(data.ItemExtrafieldsAnswers) : { label:'', icon: ''})
            }
            
            const availableJson = data.itemStocks[0]?.available || 0
            const qtyInBoxLocal = parseInt(data.itemUnitBarcodeByUnitCodeBox?.data?.coefficient) || parseInt(data.itemUnitBarcodeByUnitCodeBox?.coefficient)

            if (GetHistoryData(productID)) {
                const productHistoryFullUser = GetHistoryData(productID)
                productHistoryFullUser.forEach(e => {
                    productHistory.push({
                        date: e.created_at,
                        units: e.quantity,
                    })
                })
            }

            let stockStatus = 'outofstock'
            if (parseInt(localStorage.getItem('show.stock')) === 0) {
                isShowStock = false;
            } else if (availableJson > 20) {
                stockStatus = 'instock'
                stockText = t('niki.b2b.allproducts.instock')
            } else if (availableJson > 0) {
                stockStatus = 'low'
                stockText = t('niki.b2b.product.stocklow')
            }

            if (parseInt(localStorage.getItem('show.stock')) === 0 || parseInt(localStorage.getItem('show.stock')) === 1) isShowStockText = false;
            if (parseInt(localStorage.getItem('show.stock')) === 0)  isShowStock = false;

            const getProductBrand = () => {
                const _brand = xCompanyID === '4'
                    ? data?.brand?.name ? data.brand.name[LangFix(selectedLanguage)] || data.brand?.name : ' '
                    : data?.trademark?.name ? data.trademark.name[LangFix(selectedLanguage)] || data.trademark?.name : ' '
                return data.itemBrand?.data?.name || _brand
            }

            setProductData({
                multiplier: qtyInBoxLocal,
                name: data.itemDescriptions[selectedLanguage]?.name || data.matchcode,
                unitPrice: priceTemp,
                oldPrice: oldPrice,
                rrp: rrpJson,
                discount: Math.round((oldPrice - priceTemp) / oldPrice * 100),
                country: data.country?.data?.iso_3166_2?.toLowerCase() || data.country?.iso_3166_2.toLowerCase() || '',
                isNew: data.is_new,
                isBooklet: data.is_booklet,
                brand: data.itemBrand?.data?.name || getProductBrand(),
                barcode: data.barcode,
                unitCode: data?.sale_unit_code_translations[selectedLanguage] ? data.sale_unit_code_translations[selectedLanguage] : data.sale_unit_code,
                companyId: data.company_id,
                qtyInBox: qtyInBoxLocal,
                quantInBox: `${qtyInBoxLocal} ${data?.sale_unit_code_translations[selectedLanguage] ? data.sale_unit_code_translations[selectedLanguage] : data.sale_unit_code}`,
                capacity: CapacityString(data.capacity, data.capacity_unit_code, priceTemp),
                capacityOld: CapacityString(data.capacity, data.capacity_unit_code, oldPrice),
                gross: grossTemp,
                grossOld: grossOld,
                vat: vatTemp,
                vatOld: vatOld,
                sourceItemTypeId: data.source_item_type_id,
                saleUnitCode: data.sale_unit_code,
                validity: data.properties && data.properties.validity_days && parseInt(data.properties.validity_days) > 0 ? parseInt(data.properties.validity_days) : 0,
                ingredients: data.ingredients[0]?.ingredients ? data.ingredients[0].ingredients : '',
                nutrients: data.ingredientNutrients?.map(e => ({ name: e.description, value: e.value })) || [],
                images: productImagesArray, 
                productIcons: productIcons,
                available: availableJson,
                stock: stockStatus,
                isShowStock: isShowStock,
                stockText: stockText,
                isShowStockText: isShowStockText,
                shoppingHistory: productHistory,
                markup: rrpJson > 0 ? Math.round((rrpJson - grossTemp) / grossTemp * 100) : 0,
                margin: rrpJson > 0 ? Math.round((rrpJson-grossTemp)/rrpJson*100) : 0,
            })
            setProductItemID(data.source_item_id)
            setCurrentImage(data.attachment)
            data.family && setSimilarFacetFilters([...startFacetFilters, `family="${data.family}" AND id!="${productID}"`])
            if (data.ratings && data.ratings.length > 0) {
                const ratingData = CalculateRating(data.ratings)
                setRatingData({rating: ratingData.rating, ratingVoices: ratingData.count})
            }
            document.querySelector('.product_page_modal__wrapper').scrollTo({top: 0, behavior: 'smooth'})
        })
        .finally(() => {
            setIsLoaded(false)
            HideLoader()
        })

        return (() => {
            localStorage.setItem('is_on_product_card', 'false')
            localStorage.setItem('url.back','')
        })
        // eslint-disable-next-line
    }, [productID])

    const ShowRatingPopup = () => ratingPopupEl.current.classList.add('active')
    const HideRatingPopup = () => ratingPopupEl.current.classList.remove('active')

    const StarRatingClick = e => {
        const ratingVote = e.target.value
        const ratingURL = `${process.env.REACT_APP_API_DOMAIN}/b2b/items/${productID}/ratings`
        const requestOptionsPostRating = getRequestOptions('POST', null, 'application/json')
        requestOptionsPostRating.body = JSON.stringify({
                value: ratingVote,
                comment: '',
            })
        ShowLoader()
        fetch(ratingURL,requestOptionsPostRating)
        .then(response => {
            response.status === 200 && setRatingStatus('success')
            ShowLoader()
            GetVoteData(productID)
            .then(json => {
                json.data && setRatingData({
                    rating: json.data.average,
                    ratingVoices: json.data.count,
                })
            })
            return response.json()
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const ChangeImage = e => {
        const elDataset = e.target.dataset
        setCurrentImage(elDataset.img)
    }

    /* HistoryProduct */
    const [openHistoryProduct, setOpenHistoryProduct] = useState(false)

    useEffect(() => {
        if (openHistoryProduct) {
            const PopupSelector = `.product_history__wrapper_${productItemID}`
            document.querySelector(PopupSelector).classList.add('active')
        }      
    }, [openHistoryProduct, productItemID])
    /* HistoryProduct */
   
    const similarHit = ({ hit }) => {
        return (
            <Product {...hit} productView={'grid'} onProductClick={(id) => setProductID(id)} />
        )
    }

    const hideProductModal = () => {
        props.setProductId('')
        thisOverlayEl.current.classList.remove('active')
    }

    return (
    <div className='product_page_modal__wrapper' ref={thisOverlayEl}>
        <div className="product_page_modal__popup">
            {isLoaded
                ? <ProductPagePlaceholder />
                : <>
                    <div className="wrapper product_page">
                        <div className="product_page__images_and_badges">
                            <ul className="product_page__badges">
                                <li className={`badge_country flag-icon-${product.country}`}></li>
                                {product.isNew && <li><New /></li>}
                                {product.isBooklet && <li><Book /></li>}
                            </ul>
                            <picture className="product_page__images__main">
                                {currentImage?.endsWith("g")
                                    ? <>
                                        <source srcSet={currentImage} type="image/jpeg" />
                                        <img src={currentImage} alt={product.name} loading="lazy" />
                                    </>
                                    : <>
                                        <source srcSet={defaultImage} type="image/jpeg" />
                                        <img src={defaultImage} alt={product.name} loading="lazy" />
                                    </>
                                }
                            </picture>
                            <div className="product_page__images__additional">
                                {product?.images && product.images.map((el,i)=>
                                    <img
                                        key={`prodimg${i}`}
                                        src={el}
                                        alt={product.name}
                                        loading="lazy"
                                        data-img={el}
                                        onClick={ChangeImage}
                                    />
                                )}
                            </div>
                        </div>
                        <div>
                            <h1>{product.name}</h1>

                            <div className="product_page_modal__popup__header marginbottom20">
                                <button onClick={ShowRatingPopup}>
                                    <span className="starwrapper">
                                        <span className="starempty">
                                            <StarEmpty /><StarEmpty /><StarEmpty /><StarEmpty /><StarEmpty />
                                        </span>
                                        <span className="starsnotempty" style={{width: ratingData.rating*20+'%'}}>
                                            <Star /><Star /><Star /><Star /><Star />
                                        </span>
                                    </span>
                                    {ratingData.ratingVoices ? <span className="ratingvotes">{ratingData.ratingVoices} {t('niki.b2b.product.votes')}</span> : null}
                                </button>
                                <button className="btn btn-primary" type="button" onClick={hideProductModal}>{t('niki.b2b.returnproducts.close')}</button>     
                            </div>
                                {product?.productIcons && product.productIcons.length > 0 &&
                                    <ul className="product_page__big_icons">
                                        {product.productIcons.map((el, i) => <li key={`specicon${i}`} data-title={t(el.label)} className={`${el.icon.toLowerCase()}_icon`}></li>)}
                                    </ul>
                                }
                            <div className="product_page__price_area">
                                <div className={[`price_area__wrapper`,product.discount > 0 && 'with_sale'].join(' ')}>
                                    <div className="price_area__wrapper__old">
                                        <div className="price_area__net">
                                            <div>{t('niki.b2b.tooltip.vat')}</div>
                                            {t('niki.b2b.product.net')}
                                        </div>
                                        <div className="price_area__big_price">{PriceFormat(product.oldPrice)}</div>
                                        <div className="price_area__units">{product.capacityOld}</div>
                                    </div>
                                    <div className="price_area__wrapper__actual">
                                        <div className="price_area__net">
                                            <div>{t('niki.b2b.tooltip.vat')}</div>
                                            {t('niki.b2b.product.net')}
                                        </div>
                                        <div className="price_area__big_price">{PriceFormat(product.unitPrice)}</div>
                                        <div className="price_area__units">{product.capacity}</div>
                                        {product.discount > 0 && <div className="price_area__discount">-{product.discount}%</div>}
                                    </div>
                                </div>
                                <table className="product_page__price_area__table">
                                    <tbody>
                                        <tr>
                                            <td className="strong">
                                                <div>{t('niki.b2b.tooltip.vat')}</div>
                                                {t('niki.b2b.product.vat')}
                                            </td>
                                            <td className="strong">
                                                <div>{t('niki.b2b.tooltip.gross')}</div>
                                                {t('niki.b2b.product.gross')}
                                            </td>
                                            {product.rrp > 0 &&
                                                <td className="strong">
                                                    <div>{t('niki.b2b.tooltip.rrp')}</div>
                                                    {t('niki.b2b.product.pvp')}
                                                </td>
                                            }
                                            {product.rrp > 0 &&
                                                <td className="product_page__price">
                                                    <div>{t('niki.b2b.product.markup')}</div>
                                                    <ArrowGreenTopRight />
                                                    {product.markup}%
                                                </td>
                                            }
                                        </tr>
                                        <tr className="product_page__big" >
                                            <td>
                                                {product.vatOld > 0 && <span className="table_old_value">{PriceFormat(product.vatOld)}</span>}
                                                {PriceFormat(product.vat)}
                                            </td>
                                            <td>
                                                {product.grossOld > 0 && <span className="table_old_value">{PriceFormat(product.grossOld)}</span>}
                                                {PriceFormat(product.gross)}
                                            </td>
                                            {product.rrp > 0 && <td>{PriceFormat(product.rrp)}</td>}
                                            {product.rrp > 0 &&
                                                <td className={["product_page__price", "product_page__price_down"].join(' ')}>
                                                    <div>
                                                        {t('niki.b2b.product.margin')}
                                                    </div>
                                                    <ArrowRedBottomRight />{product.margin}%
                                                </td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="price_area__footer">
                                    <div className="price_area__footer__info">
                                    {product.shoppingHistory && product.shoppingHistory.length > 0
                                        ? <button className="order_history_units" onClick={() => setOpenHistoryProduct(true)}>
                                            <div className="order_history_units__title">{t('niki.b2b.menu.orderhistory')}</div>
                                            {product.shoppingHistory.filter((e,i) => i === 0 || i === 1).map((e,i) => <div key={`orderhistoryunit${i}`} className="order_history_unit">{FormatDate(e.date)} &mdash; {e.units} {t('niki.b2b.returnproducts.units')}</div>)}
                                        </button>
                                        : t('niki.b2b.product.noorderhistory')
                                    }
                                    </div>
                                    <div>
                                        {productsInCart.includes(productID)
                                            ? cartButtonStatus === 'waiting' 
                                                ? <button className={[`put_to_cart`, cartButtonStatus].join(' ')} style={{ backgroundColor: '#95ff99', width: 180, height: 49}}>
                                                    <div className="spinner_ring"><div></div><div></div><div></div><div></div></div>
                                                </button>
                                                : <div className="minus_plus_input">
                                                    {product.multiplier >= productQTYs[productID]
                                                        ? <button className="minus_plus_input__button minus" onClick={RemoveCartItem}><Trash /></button>
                                                        : <button className="minus_plus_input__button minus" onClick={MinusCart}>-</button>
                                                    }
                                                    <input onChange={CartInputChange} value={cartAmount} className="minus_plus_input__input" onBlur={(e) => e.target.value === '' && RemoveCartItem()}/>
                                                    <button className="minus_plus_input__button plus" onClick={PlusCart}>+</button>
                                                </div>
                                            : <button className={[`put_to_cart_big`,cartButtonStatus].join(' ')} onClick={PutToCart}>
                                                <CartPlusBig className="cart_icon" />
                                                <div className="spinner_ring"><div></div><div></div><div></div><div></div></div>
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className='wrapper_favorite_product_page'>
                                    <InFavorite data={{id: productID}} />
                                </div>
                                    {/* <FavoriteButton />  */}
                                {(product.sourceItemTypeId === 98 || product.sourceItemTypeId === 99) &&
                                    <div className={'product_supplied_partner__preview_product'}>
                                            <div> 
                                                <div><strong>{t('niki.b2b.cartflow.estimateddelivery')}</strong></div>
                                                {t('niki.b2b.product.could_different')}
                                            </div>
                                        <TruckSmall /> 
                                    </div>
                                }     
                            </div>
                            <div className="product_page__spec_table">
                                <div>
                                    <div className="product_page__spec_table__unit">
                                        <span><TradeMark /></span>
                                        <div>{t('niki.b2b.product.brand')}</div>
                                        <span>{product.brand}</span>
                                    </div>
                                    <div className="product_page__spec_table__unit">
                                        <span><Ref /></span>
                                        <div className="product_page__spec_table__unit_popup"><div>{t('niki.b2b.tooltip.ref')}</div>{t('niki.b2b.cart.ref')} </div>
                                        <span>{productItemID}</span>
                                    </div>
                                    <div className="product_page__spec_table__unit">
                                        <span><Barcode /></span>
                                        <div className="product_page__spec_table__unit_popup"><div>{t('niki.b2b.tooltip.barcode')}</div>{t('niki.b2b.product.barcode')} </div>
                                        <span>{product.barcode}</span>
                                    </div>
                                </div>
                                <div>
                                    {product.validity > 0 &&
                                        <div className="product_page__spec_table__unit">
                                            <span><ShelfLife /></span>
                                            <div className="product_page__spec_table__unit_popup"><div>{t('niki.b2b.tooltip.expiration')}</div> {t('niki.b2b.productpage.shelflife')} </div>
                                            <span>{product.validity} {t('niki.b2b.debts.days')}</span>
                                        </div>
                                    }
                                    <div className="product_page__spec_table__unit">
                                        <span><Box /></span>
                                        <div className="product_page__spec_table__unit_popup"><div>{t('niki.b2b.tooltip.box')}</div>{t('niki.b2b.product.in_box')} </div>
                                        <span>{product.quantInBox}</span>
                                    </div>
                                    {product.isShowStock &&
                                        <div className={[`product_page__spec_table__unit`, product.stock].join(' ')}>
                                            <span><span className="stock_round"></span></span>
                                            <div className={["product_page__spec_table__unit_popup", "available_units"].join(' ')}>
                                                <div>{t('niki.b2b.tooltip.in_stock')}</div> {product.stockText}  
                                            </div>
                                            <span className="available_units">
                                                {product.isShowStockText && `${PriceFormat(product.available, false, false)} ${product.unitCode}`}
                                            </span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {(product?.ingredients?.length > 0 || product?.nutrients?.length > 0) &&  <ProductIngredientsNutrients ingredients={product.ingredients} nutrients={product.nutrients}/>}
            
            <div className="wrapper marginbottom40">
                <div className="marketing_four_banners">
                    {bannersMiddle.map((e, i) => <div key={`middlebanner${i}`} onClick={() => onBannerView(e, navigate)}>
                        <LoadableImage src={e.image} alt={e.title} height={'310px'}/>
                    </div>)}
                </div>
            </div>

            <h3 className="center paddingbottom40">{t('niki.b2b.productpage.similarproducts')}</h3>
            <div className="wrapper">
                <InstantSearch
                    indexName={getMeilisearchIndexName("items")}
                    searchClient={searchClient}
                >
                    <Configure
                        facetFilters={similarFacetFilters}
                        hitsPerPage={12}
                        attributesToHighlight={[]}
                        attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions',`${xCompanyID === '4' ? 'brand' : 'trademark' }`,'country','kinds']}
                    />
                    <Hits hitComponent={similarHit} />
                </InstantSearch>
            </div>

            <div className="rating_popup__wrapper" ref={ratingPopupEl}>
                <div className="rating_popup__content">
                    <div className="star-rating">
                        <input type="radio" name="rating" value="1" onClick={StarRatingClick} /><i></i>
                        <input type="radio" name="rating" value="2" onClick={StarRatingClick} /><i></i>
                        <input type="radio" name="rating" value="3" onClick={StarRatingClick} /><i></i>
                        <input type="radio" name="rating" value="4" onClick={StarRatingClick} /><i></i>
                        <input type="radio" name="rating" value="5" onClick={StarRatingClick} /><i></i>
                    </div>
                    <div className="hide_on_voted center">{t('niki.b2b.vote.registervote')}.</div>
                    {ratingStatus === 'success' && <div className="rating_popup__success"><InfoWhite /> {t('niki.b2b.vote.voted')}</div>}
                    <div className="rating_popup__summary">
                        {t('niki.b2b.vote.results')}
                        <span className="starwrapper">
                            <span className="starempty">
                                <StarEmpty /><StarEmpty /><StarEmpty /><StarEmpty /><StarEmpty />
                            </span>
                            <span className="starsnotempty" style={{width: ratingData.rating*20+'%'}}>
                                <Star /><Star /><Star /><Star /><Star />
                            </span>
                        </span>
                        ({ratingData.ratingVoices} {t('niki.b2b.product.votes')})
                    </div>
                    <button className="rating_popup__close" onClick={HideRatingPopup}></button>
                </div>
                <div className="rating_popup__overlay" onClick={HideRatingPopup}></div>
            </div>
            {openHistoryProduct &&
                <ProdHistoryPopup
                    matchcode={product.name}
                    unit_code={product.unitCode}
                    qty_in_box={product.qtyInBox}
                    item_id={productItemID}
                    imgsrc={currentImage}
                    setOpenHistoryProduct={setOpenHistoryProduct}
                    product_id={productID}
                />
            }
        </div>
        <div className="product_page_modal__overlay" onClick={hideProductModal}></div>
    </div>
    )
}