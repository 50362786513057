import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { searchClient, priceLineID, xCompanyID } from '../config'
import { ShowLoader, HideLoader, GetGlobalBannerData, ArrayUniqe, onBannerView } from '../functions'
import { getMeilisearchIndexName } from '../helpers/apiHelper'

import { InstantSearch, Configure, Hits } from 'react-instantsearch-dom'

import { Product } from '../components/product/Product'
import LoadableImage from "../components/LoadableImage/LoadableImage"

import '../assets/css/mainpage.css'

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

let segmentationInsert = ''
if (localStorage.getItem('account.segmentationids')) segmentationInsert = ArrayUniqe(localStorage.getItem('account.segmentationids').split(',')).join(',')

const bookletFacetFilters = [
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    localStorage.getItem('marketingCampaignIds')
        ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.booklet}]`
        : `is_booklet=true`,
    `itemSegmentations IN [${segmentationInsert}]`,
]
const alcoholicFacetFilters = [
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    localStorage.getItem('marketingCampaignIds')
        ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.promo}]`
        : `is_discount=true`,
    `itemSegmentations IN [${segmentationInsert}]`,
]

export const MainPage = () => {
    
    const navigate = useNavigate()

    const productHit = ({ hit }) => {
        return (
            <Product {...hit} productView={'grid'} />
        )
    }

    const [bannersTop, setBannersTop] = useState([])
    const [bannersMiddle, setBannersMiddle] = useState([])
    const [bannersBottom, setBannersBottom] = useState([])
    const [bannerDataLoaded, setBannerDataLoaded] = useState(false)
    
    useEffect(() => {
        ShowLoader()
        const data = JSON.parse(localStorage.getItem('banners_data'))
        data && setBannerDataLoaded(true)
        data && data.forEach(e => {
            e.content_subtype_id === 'yPrdNR7m4nbgEjmq' && e.contentType?.data?.real_id === 2 && setBannersBottom(GetGlobalBannerData(e))
            e.content_subtype_id === 'RgpxYPa3MnJjqMw8' && e.contentType?.data?.real_id === 2 && setBannersMiddle(GetGlobalBannerData(e))
            e.content_subtype_id === 'k9peXdnvO7DQ0m5y' && e.contentType?.data?.real_id === 2 && setBannersTop(GetGlobalBannerData(e))
        })
        HideLoader()
    }, [])

    return (
        <>
            {(bannersTop.length === 0 && !bannerDataLoaded) && <div className="top_banner_preloader content_placeholder"></div>}
            {(bannersTop.length > 0 && bannerDataLoaded) &&
            <div className="banner450_wrapper">
                <Carousel
                    showThumbs={false}
                    showStatus={false}
                    swipeable={true}
                    >{bannersTop.map((e, i) => <div onClick={() => onBannerView(e, navigate)} key={`slide450${i}`} className="banner450_slide">
                        <LoadableImage src={e.image} alt={e.title} height={'350px'}/>
                    </div>)}</Carousel>
            </div>
            }

            {bannersMiddle.length > 0 &&
                <div className="two_banners wrapper">
                        {bannersMiddle.map((e, i) => <div key={`middlebanner${i}`} onClick={() => onBannerView(e, navigate)}>
                            <LoadableImage src={e.image} alt={e.title} height={'310px'} />
                        </div>)}
                </div>
            }

            <>
                <h3 className="main_page__h3 center">{t('niki.b2b.mainpage.featuredbooklet')}</h3>
                <div className="wrapper">
                    <InstantSearch
                        indexName={getMeilisearchIndexName("items")}
                        searchClient={searchClient}
                    >
                        <Configure
                            facetFilters={bookletFacetFilters}
                            hitsPerPage={12}
                            attributesToHighlight={[]}
                            attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions',`${xCompanyID === '4' ? 'brand' : 'trademark' }`,'country','kinds']}
                        />
                        <Hits hitComponent={productHit} />
                    </InstantSearch>
                </div>
            </>
            
            {bannersBottom.length > 0 &&
                <>
                    <h3 className="main_page__h3 center">{t('niki.b2b.mainpage.discover')}</h3>
                    <div className="two_banners wrapper">
                        {bannersBottom.map((e, i) => <div onClick={() => onBannerView(e, navigate)} key={`bottombanner${i}`}>
                            <LoadableImage src={e.image} alt={e.title} height={'310px'} />
                        </div>)}
                    </div>
                </>
            }

            <>
                <h3 className="main_page__h3 center">{t('niki.b2b.mainpage.alcoholic')}</h3>
                <div className="wrapper">
                    <InstantSearch
                        indexName={getMeilisearchIndexName("items")}
                        searchClient={searchClient}
                    >
                        <Configure
                            facetFilters={alcoholicFacetFilters}
                            hitsPerPage={12}
                            attributesToHighlight={[]}
                            attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions','brand','trademark','country','kinds']}
                        />
                        <Hits hitComponent={productHit} />
                    </InstantSearch>
                </div>
            </>
        </>
    )
}