import { t } from "i18next"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from 'react-redux'

import { setIsMore5CartsAlert } from '../reduxToolkit/universalSlice'
import { requestCreateNewCart } from "../helpers/apiHelper"
import { ShowLoader, HideLoader, storingCartData } from "../functions"

import "../assets/css/cart.css"

export const CreateNewCartModal = ({ close }) => {

    const dispatch = useDispatch()
    
    const cartRenameInputEl = useRef(null)
    const refWrapper = useRef(null)

    const [isCloseModal, setIsCloseModal] = useState(false)
    const [newCartName, setNewCartName] = useState('')

    const closeModal = () => {
        refWrapper.current.classList.remove('active')
        setIsCloseModal(true)
    }

    useEffect(() => {
        refWrapper.current.classList.add('active')

        let timer;
        clearTimeout(timer);
        timer = setTimeout(() => {
            cartRenameInputEl.current.focus()
        },500)
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let timer;
        if (isCloseModal) {
            clearTimeout(timer);
            timer = setTimeout(() => close(), 400)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isCloseModal])


    const CreateNewCart = e => {
        e.preventDefault()
        ShowLoader()
        requestCreateNewCart(newCartName)
            .then(response => response.json())
            .then(json => {
                if (json.message && !json.message.includes("authenticate")) {
                    closeModal()
                    dispatch(setIsMore5CartsAlert(true)) 
                }
                if (json.data) {
                    storingCartData(json.data.uuid, json.data.id_real, json.data.id)
                    closeModal()
                    // todo
                    window.location.href = '/myshoppingcart/'
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }
    
    return (
        <div className="rename_cart__wrapper" ref={refWrapper}>
                <form className="rename_cart__popup" onSubmit={CreateNewCart}>
                    <div className="rename_cart__popup__title">{t('niki.b2b.cart.enter_cart_name')}</div>
                    <div>
                        <input
                            className="form-control"
                            name="newcartname"
                            ref={cartRenameInputEl}
                            onChange={e => setNewCartName(e.target.value)}
                        />
                    </div>
                    <div className="rename_cart__popup__buttons">
                        <button className="btn btn-primary" type="submit">{t('niki.b2b.cart.create')}</button>
                        <button className="btn" type="button" onClick={closeModal}>{t('niki.b2b.cartflow.cancel')}</button>
                    </div>
                    <button className="rename_cart__popup__close" type="button" onClick={closeModal}></button>
                </form>
                <div className="rename_cart__overlay" onClick={closeModal}></div>
            </div>
    )
}