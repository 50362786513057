import React, { useRef, useState } from 'react'
import { t } from "i18next"

import { languages, languagesArray, defaultCompanyLanguage } from '../../config'
import { LangFix, returnLangForFlag } from '../../functions'

export const ChooseLang = () => {
    const LangSelectorEl = useRef(null)
    const lang = LangFix(localStorage.getItem('locale') || defaultCompanyLanguage)
    !localStorage.getItem('locale') && localStorage.setItem('locale', defaultCompanyLanguage === 'en' ? 'gb' : defaultCompanyLanguage)

    const [isOpenLang,setOpenLang] = useState(false)
    const [selectedLang,setLang] = useState({
        flag: returnLangForFlag(lang),
        country: languagesArray[returnLangForFlag(lang)]
    })
    
    const SelectLang = e => {
        if (e.target.dataset.flag === localStorage.getItem('locale')) {
            LangSelectorEl.current.classList.remove('active')
        } else {
            setLang({
                flag: e.target.dataset.flag,
                country: languagesArray[returnLangForFlag(LangFix(e.target.dataset.flag))]
            })
            LangSelectorEl.current.classList.remove('active')
            localStorage.setItem('locale', e.target.dataset.flag)
            localStorage.setItem('locale.full', e.target.dataset.country)
            document.location.reload()
        }
    }
    
    const Langs = languages.map((item,key) => (
        <li key={key} className={'lang_'+item.flag} data-flag={item.flag} data-country={item.country} data-translateCountry={item.translateCountry} onClick={SelectLang}>
            <span className={'login_form__flag flag-icon-squared flag-icon-'+item.flag}></span>
            {t(item.translateCountry)}
        </li>
    ))
    const explandCollapseLangs = () => {
        LangSelectorEl.current.classList.toggle('active')
    }
   
    function closeModalOnClickOutside(event) {
        const modal = document.getElementById('lang_table')
        const lang = document.getElementById('lang')
        if (event.target === lang) {
            setOpenLang(true)
        } else if (event.target !== modal) {
            isOpenLang && LangSelectorEl.current.classList.remove('active')
            setOpenLang(false)
        }
    }
    document.addEventListener('click', closeModalOnClickOutside)
    
    return (
        <div className="login_form__lang_selector">
            <div className="login_form__lang_selector__selected" onClick={explandCollapseLangs} id='lang'>
                <span className={'login_form__flag flag-icon-'+selectedLang.flag}></span>
                {t(selectedLang.country)}
            </div>
            <ul className="login_form__lang_selector__wrapper" ref={LangSelectorEl} id='lang_table'>{Langs}</ul>
        </div>
    )
}