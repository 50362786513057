import { priceLineID } from '../config'
import { GetMainImageCart, getTranslatedName, getTranslateStockStatus, getStockStatus } from '../functions'
import { addCartProduct } from '../reduxToolkit/cartSlice'


export const prepareProductToCart = (p, dispatch, returnResult = false) => {
    let price = !!p.unit_price ? parseFloat(p.unit_price.toFixed(2)) : 0
    let taxIncludedPrice = !!p.tax_included_price ? parseFloat(p.tax_included_price.toFixed(2)) : 0

    if (p.item?.data?.itemPrices?.data) {
        const itemPrices = p?.item?.data?.itemPrices.data.length ? p?.item?.data?.itemPrices.data.filter(i => i.priceline_id === priceLineID)[0] : p?.item?.data?.itemPrices.data
        if (itemPrices?.unit_price) price = parseFloat(itemPrices?.unit_price)
        if (itemPrices?.tax_included_price) taxIncludedPrice = parseFloat(itemPrices?.tax_included_price)
    }

    let priceOld = 0
    if (p.item?.data?.itemDiscounts?.data) {
        priceOld = price
        const itemProductDiscounts = p?.item?.data?.itemDiscounts.data.length
            ? p.item.data.itemDiscounts.data.filter(i => i.priceline_id === priceLineID)[0] || p.item.data.itemDiscounts.data[0]
            : p?.item?.data?.itemDiscounts.data || { unit_price: 0, tax_included_price: 0 }
        if(itemProductDiscounts?.unit_price) price = parseFloat(itemProductDiscounts.unit_price)
        if(itemProductDiscounts?.tax_included_price) taxIncludedPrice = parseFloat(itemProductDiscounts.tax_included_price)
    }
    const prodImage = p?.item?.data?.attachment?.data?.awsFileUrl ? p.item.data.attachment.data.awsFileUrl : GetMainImageCart(p.item.data.attachments, p.item.data.attachment_id)
    const vat = taxIncludedPrice > price ? (taxIncludedPrice - price) : 0
    
    const productData = {
        name: getTranslatedName(p.item?.data) ? getTranslatedName(p.item?.data) : p.matchcode,    
        id: p.id,
        itemid: p.product_item_id,
        productid: p.product_id,
        productitemid: p.product_item_id,
        qty: p.qty,
        qtyinbox: +p.qty_in_box,
        unitcode: p.UNI,
        price: price,
        priceold: priceOld || price,
        vat,
        image: prodImage,
        sale_unit_code: p.sale_unit_code,
        entity_account_id: p.item?.data?.entityAccount?.data?.id || '',
        estimated_delivery: p.item?.data?.entityAccount?.data?.estimated_delivery || null,

        translateStock: getTranslateStockStatus(p),
        instock: getStockStatus(p),
        total: (p.qty * price + vat).toFixed(2),
        total_net: (p.qty * price).toFixed(2),
        group: p?.item?.data?.itemFamily?.data?.description || '',
        type: p?.item?.data?.itemKinds?.data[0]?.name || '',
        item_type_id: p?.item?.data?.item_type_id ? p.item.data.item_type_id : '',
        // todo
        idForGruppingSupplier: p?.item?.data?.item_type_id ? p.item.data.item_type_id : '',
        supplier: p?.item?.data?.supplier_name ? p.item.data.supplier_name : '',
    }

    if (returnResult) {
        return productData
    } else { dispatch(addCartProduct(productData)) }
}