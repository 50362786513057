import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { updateCartProduct, deleteCartProduct, setDeleteProductID, setOpenDeleteProductAlert, setIsProductSearch, setShoppingCartSummaryCleare, setIdProductThatIsInCart } from "../../reduxToolkit/cartSlice"

import { ShowLoader, HideLoader, checkValueOrNonInteger } from '../../functions'
import { updateProduct } from "../../reduxToolkit/productsSlice"
import { getRequestApi } from "../../helpers/apiHelper"

import { ReactComponent as Trash } from '../../assets/svg/trash.svg'

const requestUpdateItemValue = (cartDetail, localValue) => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${cartDetail}`
    const body = JSON.stringify({ qty: localValue })
    const response = getRequestApi({ URL, method: 'POST', contentType: 'application/json', body })
    return response 
}

const requestRemoveCartItem = cartDetail => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${cartDetail}`
    const response = getRequestApi({URL, method: 'DELETE'})
    return response
}

export const InputRenderer = params => {

    const dispatch = useDispatch()
      
    const [inputValue, setInputValue] = useState(params.data.qty)
    const [cartButtonStatus, setCartButtonStatus] = useState('normal')
    const [isChangedInput, setChangedInput] = useState(false)

    const idProductThatIsInCart = useSelector(state => state.cartContent.idProductThatIsInCart)

    const multiplier = params.data.qtyinbox
    const cartDetail = params.data.id
    const productid = params.data.productid
    const price = params.data.price
    
    const RemoveCartItem = cartDetail => {
        ShowLoader()
        setCartButtonStatus('waiting')
        requestRemoveCartItem(cartDetail)
            .then(response => {
                if (response.ok) {
                    setInputValue(0)
                    params.api.applyTransaction({ remove: [params.data] })
                    params.data.setDeleteRowIndex(params.rowIndex)
                    dispatch(deleteCartProduct(cartDetail))
                    dispatch(setDeleteProductID(cartDetail))
                    dispatch(updateProduct({ id: productid, qty: 0 }))
                }
            })
            .catch(error => console.error(error))
            .finally(() => {
                HideLoader()
                setCartButtonStatus('normal')
            })
    }

    const applyTotal = inputValue => {
        const newData = params.data
        newData.qty = inputValue
        newData.total_net = inputValue * price
        params.api.applyTransaction({update: [newData]})
    }

    const UpdateCartValue = (value, cartDetail, from) => {
        const localValue = typeof value === "string" ? parseFloat(value) : value
        if (localValue === 0) {
            RemoveCartItem(cartDetail)
            return
        }

        ShowLoader()
        setCartButtonStatus('waiting')
        requestUpdateItemValue(cartDetail, localValue)
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    setInputValue(json.data.qty)
                    applyTotal(json.data.qty)
                    dispatch(updateCartProduct({ productid: productid, cartdetail: cartDetail, qty: json.data.qty }))
                    dispatch(updateProduct({ id: productid, qty: json.data.qty }))
                    dispatch(setShoppingCartSummaryCleare())
                    dispatch(setIsProductSearch(true))
                } else {
                    setInputValue(params.data.qty)
                }
            })
            .catch(error => {
                setInputValue(params.data.qty)
                console.error(error)
            })
            .finally(() => {
                HideLoader()
                setCartButtonStatus('normal')
            }) 
    }
    
    useEffect(() => {
        if (idProductThatIsInCart && params.data.productid === idProductThatIsInCart) {
            params.data.qty += multiplier
            const summ = inputValue+multiplier
            setInputValue(summ)
            UpdateCartValue(summ,cartDetail)
            applyTotal(summ)
            dispatch(setIdProductThatIsInCart(''))
        }    
        // eslint-disable-next-line
    }, [idProductThatIsInCart])

    const PlusCart = () => {
        const summ = inputValue + multiplier
        const resultSum = Number.isInteger(summ) ? summ : summ.toFixed(2)
        UpdateCartValue(resultSum,cartDetail,'plus')
    }

    const MinusCart = e => {
        let subtractionResult = inputValue - multiplier
        let difference = Number.isInteger(subtractionResult) ? subtractionResult : subtractionResult.toFixed(2)

        if (difference < multiplier) difference = multiplier
        UpdateCartValue(difference,cartDetail)
    }

    const ChangeCartInput = e => {

        const value = e.target.value
        typeof value === "string" && !value && setInputValue('')
        
        const localValue = checkValueOrNonInteger(value, params.data.sale_unit_code)
        if (localValue === '') return 
        
        setInputValue(localValue)
        setChangedInput(true)
    }
    
    const openAlertPop = () => {
        params.data.setDeleteProduct({ cartDetail: params.data.id, productid: params.data.productid })
        dispatch(setOpenDeleteProductAlert(true))
    }
    
    useEffect(() => {
        let timer;
        if (isChangedInput) {
            if (inputValue) {
                clearTimeout(timer);
                timer = setTimeout(() => UpdateCartValue(inputValue, cartDetail), 1950);
            } else {
                clearTimeout(timer);
                timer = setTimeout(() => openAlertPop(), 2650);
            }  
            setChangedInput(false)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [inputValue]);

    const DeleteCart = () => {
        RemoveCartItem(cartDetail)
    }
    return (
        <>
            {cartButtonStatus === 'waiting'
                ? <button className={[`put_to_cart`, cartButtonStatus, 'put_to_cart_big_size', 'put_to_cart_notempty'].join(' ')} style={{ height: 40 }}>
                    <div className="spinner_ring"><div></div><div></div><div></div><div></div></div>
                </button>
                : <div className="aggrid_plusminus_group">
                    {inputValue <= params.data.qtyinbox || inputValue === 1
                        ? <button className="apg_button_delete" onClick={DeleteCart}><Trash /></button>
                        : <button className="apg_button_minus" onClick={MinusCart}>-</button>
                    }
                    <input
                        type="text"
                        className="apg_input"
                        value={inputValue}
                        name={params.data.id}
                        onChange={ChangeCartInput}
                        onBlur={(e) => e.target.value === '' && RemoveCartItem(cartDetail)}
                    />
                    <button className="apg_button_plus" onClick={PlusCart}>+</button>
                </div>
            }
        </>
    )
}