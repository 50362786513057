import { createSlice } from "@reduxjs/toolkit";

const universalSlice = createSlice({
    name: "universal",
    initialState: {
        isNoEntityAccount: false,
        isOpenTermsSaleAlert: false,
        isClickOnAllProductsInHeader: false,
        isShowAllShoppingCartsModal: false,
        isMore5CartsAlert: false,
        isAccountBlockedModal: false,
        isAccountBlocked: false,
        isOpenAccountPopup: false,
        isOpenProductNotFoundAlert: false,
        isOpenNoCartAlert: false,
        isOpenCreateNewCartModal: false,
        isClickOnBannerAllProducts: false,
        isNotImpossiblePrint: false,
        isInfoModalOpen: false,
        contentInfoModal: '',
        isShowMainHeaderSearch: false,
        isShowHeaderSearch: false,
        isShowMainSearch: true,
        isRefreshMarketingCampaigns: false,
        isReadyFavorites: false,
        isChangeAccount: false,
        isChangeSelectedShoppingCard: false,
    },
    reducers: {
        setIsNoEntityAccount(state, action) {
            state.isNoEntityAccount = action.payload
        },
        setIsOpenTermsSaleAlert(state, action) {
            state.isOpenTermsSaleAlert = action.payload
        },
        setIsClickOnAllProductsInHeader(state, action) {
            state.isClickOnAllProductsInHeader = action.payload
        },
        setIsShowAllShoppingCartsModal(state, action) {
            state.isShowAllShoppingCartsModal = action.payload
        },
        setIsMore5CartsAlert(state, action) {
            state.isMore5CartsAlert = action.payload
        },
        setIsAccountBlockedModal(state, action) {
            state.isAccountBlockedModal = action.payload
        },
        setIsAccountBlocked(state, action) {
            state.isAccountBlocked = action.payload
        },
        setIsOpenAccountPopup(state, action) {
            state.isOpenAccountPopup = action.payload
        },
        setIsOpenProductNotFoundAlert(state, action) {
            state.isOpenProductNotFoundAlert = action.payload
        },
        setIsOpenNoCartAlert(state, action) {
            state.isOpenNoCartAlert = action.payload
        },
        setIsOpenCreateNewCartModal(state, action) {
            state.isOpenCreateNewCartModal = action.payload
        },
        setIsClickOnBannerAllProducts(state, action) {
            state.isClickOnBannerAllProducts = action.payload
        },
        setIsNotImpossiblePrint(state, action) {
            state.isNotImpossiblePrint = action.payload
        },
        setIsInfoModalOpen(state, action) {
            state.isInfoModalOpen = action.payload
        },
        setContentInfoModal(state, action) {
            state.contentInfoModal = action.payload
        },
        setIsShowMainHeaderSearch(state, action) {
            state.isShowMainHeaderSearch = action.payload
        },
        setIsShowHeaderSearch(state, action) {
            state.isShowHeaderSearch = action.payload
        },
        setIsShowMainSearch(state, action) {
            state.isShowMainSearch = action.payload
        },
        setIsRefreshMarketingCampaigns(state, action) {
            state.isRefreshMarketingCampaigns = action.payload
        },
        setIsReadyFavorites(state, action) {
            state.isReadyFavorites = action.payload
        },
        setIsChangeAccount(state, action) {
            state.isChangeAccount = action.payload
        },
        setIsChangeSelectedShoppingCard(state, action) {
            state.isChangeSelectedShoppingCard = action.payload
        },
    }
})


export default universalSlice.reducer
export const {
    setIsNoEntityAccount,
    setIsOpenTermsSaleAlert,
    setIsClickOnAllProductsInHeader,
    setIsShowAllShoppingCartsModal,
    setIsMore5CartsAlert,
    setIsAccountBlockedModal,
    setIsAccountBlocked,
    setIsOpenAccountPopup,
    setIsOpenProductNotFoundAlert,
    setIsOpenNoCartAlert,
    setIsOpenCreateNewCartModal,
    setIsClickOnBannerAllProducts,
    setIsNotImpossiblePrint,
    setIsInfoModalOpen,
    setContentInfoModal,
    setIsShowMainHeaderSearch,
    setIsShowHeaderSearch,
    setIsShowMainSearch,
    setIsRefreshMarketingCampaigns,
    setIsReadyFavorites,
    setIsChangeAccount,
    setIsChangeSelectedShoppingCard,
} = universalSlice.actions