import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { t } from 'i18next'

import { addReturnSelected, updateReturnSelectedReason, addReason, clearReturnSelected, removeReturnSelected } from '../reduxToolkit/returnsSlice'
import { setIsInfoModalOpen, setContentInfoModal } from '../reduxToolkit/universalSlice'

import { priceLineID, xCompanyID, xTenant } from "../config"
import { PriceFormat, ShowLoader, HideLoader, DateFormat, TranslateJSON, getTranslatedName, LangFix } from '../functions'

import { Breadcrumbs } from "../breadcrumbs"
import { InputRendererReturns } from '../components/inputRendererReturns'
import { TablePreloader } from '../components/TablePreloader'
import { getRequestOptions } from '../helpers/apiHelper'

import "./../assets/css/staticpages.css"

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

import { AG_GRID_LOCALE } from '../aggridtranslations/locale.full'

import { ReactComponent as ArrowRight } from './../assets/svg/returnproductsarrowright.svg'

import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey("CompanyName=SmartDigit, Lda,LicensedApplication=NIKI24,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-030570,SupportServicesEnd=27_August_2023_[v2]_MTY5MzA5MDgwMDAwMA==197101bde71f7a31a7dac0b3d3f0a6c8")

const gridStyles = {
    color: 'var(--darkgrey)',
    fontSize: '16px',
    overflow: 'visible',
    rowGroupPanelShow: 'always'
}

const requestOptions = () => {
    return {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        }
    }
}

const RenderImageInGrid = (params) => {

    const location = useLocation()

    const onClickGoToProduct = () => {
        localStorage.setItem('url.back', `${location.pathname}`)
        localStorage.setItem('clearselectprods', 'no')
    }

    return (
        <NavLink to={`/productpage/${params.data.id}/`} onClick={onClickGoToProduct} className="grid_prod_img">
            <img src={params.data.image} alt={params.data.productName} loading="lazy" />
        </NavLink>
    )
}

const columnDefsDataOne = [
    {
        field: 'item_id',
        headerName: 'ID',
        width: 120,
        pinned: 'left',
        checkboxSelection: true,
        rowMultiSelectWithClick: true,
        // suppressRowClickSelection: true,
    },
    {
        headerName: '',
        field: 'prodimg',
        width: 110,
        floatingFilter: false,
        cellRenderer: RenderImageInGrid
    },
    {
        field: 'productName',
        headerName: t('niki.b2b.cartflow.product'),
        filter: 'agTextColumnFilter',
        width: 330,
        autoHeight: true,
        cellRenderer: params => {
            return <strong className={["ag_prodname", "line_heigh_inherit"].join(' ')}>{params.data.productName}</strong>
        }
    },
    {
        field: 'group',
        headerName: t('niki.b2b.shoppingcart.group'),
        width: 120,
        enableRowGroup: true,
        filter: false,
    },
    {
        field: 'type',
        headerName: t('niki.b2b.feedback.type'),
        width: 115,
        enableRowGroup: true,
        filter: false,
    },
    {
        field: 'price',
        headerName: t('niki.b2b.cart.unipr'),
        filter: 'agTextColumnFilter',
        width: 130,
        cellStyle: { 'justifyContent': 'end' },
        cellRenderer: params => `${PriceFormat(params.value)}`
    },
    {
        field: 'total',
        headerName: t('niki.b2b.cart.total'),
        filter: 'agTextColumnFilter',
        width: 130,
        cellStyle: { 'justifyContent': 'end' },
        cellRenderer: params => <strong>{PriceFormat(params.value)}</strong>
    },
    {
        field: 'vat',
        headerName: t('niki.b2b.product.vat'),
        filter: 'agTextColumnFilter',
        width: 100,
        cellStyle: { 'justifyContent': 'end' },
        cellRenderer: params => `${params.value}%`,
        enableRowGroup: true,
    },
    {
        field: 'qty',
        headerName: t('niki.b2b.product.quantity'),
        filter: 'agTextColumnFilter',
        width: 110,
        cellStyle: { 'justifyContent': 'center' },
        cellRenderer: params => `${params.value}`
    },
]

const columnDefsDataTwo = [
    {
        headerName: '',
        field: 'prodimg',
        width: 90,
        floatingFilter: false,
        checkboxSelection: false,
        pinned: 'left',
        cellRenderer: RenderImageInGrid
    },
    {
        field: 'itemID',
        headerName: 'ID',
        width: 120,
        pinned: 'left',
        // cellRenderer: 'agGroupCellRenderer',
    },
    {
        field: 'productName',
        headerName: t('niki.b2b.cartflow.product'),
        filter: 'agTextColumnFilter',
        width: 320,
        autoHeight: true,
        cellRenderer: params => {
            return <strong className="ag_prodname">{params.data.productName}</strong>
        }
    },
    {
        field: 'group',
        headerName: t('niki.b2b.shoppingcart.group'),
        width: 180,
        filter: false,
    },
    {
        field: 'type',
        headerName: t('niki.b2b.feedback.type'),
        width: 100,
        filter: false,
    },
    {
        headerName: t('niki.b2b.product.quantity'),
        cellRenderer: InputRendererReturns,
    },
    {
        field: 'price',
        headerName: t('niki.b2b.cart.unipr'),
        filter: 'agTextColumnFilter',
        width: 120,
        cellStyle: { 'justifyContent': 'end' },
        cellRenderer: params => `${PriceFormat(params.value)}`
    },
    {
        field: 'total',
        headerName: t('niki.b2b.cart.total'),
        filter: 'agTextColumnFilter',
        width: 120,
        cellStyle: { 'justifyContent': 'end' },
        cellRenderer: params => <strong>{PriceFormat(params.value)}</strong>
    },
]

export const ReturnProducts = () => {

    const { id } = useParams()
    const orderID = id
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const gridRef = useRef()
    const selectedContentQTYs = useSelector(state => state.returnsContent.selectedContentQTYs)
    const selectedContent = useSelector(state => state.returnsContent.selectedContent)
    const [orderData, setOrderData] = useState({})
    const [rowData, setRowData] = useState([])
    const [rowData2, setRowData2] = useState([])
    const [step, setStep] = useState('one')
    const [returnIDReal, setReturnIDReal] = useState(0)
    const [returnKey, setKey] = useState('')
    const [dataLoaded, setDataLoaded] = useState(false)
    const [loadingStatus, setLoadingStatus] = useState('waiting')
    const [disabled, setDisabled] = useState(false)
    const [isCellClicked, setIsCellClicked] = useState(false)

    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            // filter: 'agTextColumnFilter',
            // floatingFilter: true
        }
    }, [])

    const onCellClicked = (event) => {
        const selectedProductsId = []
        selectedContent.forEach(p => selectedProductsId.push(p.id))
        setIsCellClicked(true)

        if (step === 'one') {
            // if (selectedProductsId.includes(event.node.data.id)) {

            //     const selectedProducts = selectedProductsId.filter(p => p !== event.node.data.id)

            //     dispatch(removeReturnSelected(event.node.data.uuid || ''))
            //     gridRef.current.api.forEachNode(node => {
            //         console.log('WWWWWWWWWWW', node, selectedProducts.includes(node.data.id))
            //         if (selectedProducts.includes(node.data.id)) {
            //             console.log('RRRRRRRRRRRRR', node)
            //             gridRef.current.api.selectNode(node, true)
            //         } 
            //     })
            //     console.log('1111111111', selectedProducts)

            // } else {
            const tempObj = {
                id: event.node.data.id,
                uuid: event.node.data.uuid,
                productName: event.node.data.productName,
                itemID: event.node.data.item_id,
                image: event.node.data.image,
                group: event.node.data.group,
                type: event.node.data.type,
                price: event.node.data.price,
                qty: event.node.data.qty,
                qtyReturn: 0,
                reason: '',
                total: parseFloat(event.node.data.qty * event.node.data.price).toFixed(2)
            }
            dispatch(addReturnSelected(tempObj))

            const selectedId = []
            selectedContent.forEach(e => selectedId.push(e.id))
            gridRef.current.api.forEachNode(node => {
                if (selectedId.includes(node.data.id)) {
                    gridRef.current.api.selectNode(node, true)
                }
            })
            // }
        }
    }

    const onSelectionChanged = (event) => {
        if (isCellClicked) {
            setIsCellClicked(false)
            return
        }
        const selectedRows = gridRef.current.api.getSelectedRows()

        if (step === 'one') {
            if (selectedContent.length > selectedRows.length) {
                function findDeletedElement(arr1, arr2) {
                    const set2 = new Set(arr2.map(obj => obj.uuid))
                    for (const obj of arr1) {
                        if (!set2.has(obj.uuid)) {
                            return obj
                        }
                    }
                }
                const deleteProduct = findDeletedElement(selectedContent, selectedRows)
                dispatch(removeReturnSelected(deleteProduct?.uuid || ''))
            } else {
                selectedRows.forEach(e => {
                    const tempObj = {
                        id: e.id,
                        uuid: e.uuid,
                        productName: e.productName,
                        itemID: e.item_id,
                        image: e.image,
                        group: e.group,
                        type: e.type,
                        price: e.price,
                        qty: e.qty,
                        qtyReturn: 0,
                        reason: '',
                        total: parseFloat(e.qty * e.price).toFixed(2)
                    }
                    dispatch(addReturnSelected(tempObj))
                })
            }
        }
    }

    const localeText = useMemo(() => {
        return TranslateJSON(AG_GRID_LOCALE)
    }, [])

    const onGridReady = useCallback((params) => {
        if (step === 'one' && selectedContent?.length > 0) {
            const selectedId = []

            selectedContent.forEach(e => selectedId.push(e.id))
            params.api.forEachNode(node => {
                if (selectedId.includes(node.data.id)) {
                    params.api.selectNode(node, true)
                }
            })
        }
    }, [step, selectedContent]);

    useEffect(() => {
        localStorage.setItem('clearselectprods', 'yes')
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/return-types`, requestOptions())
            .then(response => response.json())
            .then(json => {
                json.data && json.data.forEach(e => dispatch(addReason({ id: e.id, name: e.matchcode })))
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())

        return (() => {
            localStorage.getItem('clearselectprods') === 'yes' && dispatch(clearReturnSelected())
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents/${orderID}?include=details.item,details.item.itemDescriptions,status&realKey=true&showVat=true&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                setRowData([])
                json.data && setReturnIDReal(json.data.id_real)
                json.data && setKey(`${json.data.key} - ${json.data.serial}`)
                json.data && setOrderData({
                    id: json.data.id,
                    idReal: json.data.id_real,
                    processID: json.data.process_id,
                    processIDReal: json.data.process_id_real,
                    date: json.data.created_at,
                    invoice: `${json.data.key} ${String(json.data.number).padStart(6, '0')}`,
                })
                json.data.details && json.data.details.data && json.data.details.data.forEach(e => {
                    const qty = e.quantity || 0;
                    const price = e.last_cost_price || 0;

                    const tempObj = {
                        id: e.item?.data?.id || '',
                        productName: getTranslatedName(e.item.data) ? getTranslatedName(e.item.data) : e.description,
                        item_id: (e.item && e.item.data && e.item.data.item_id) || '',
                        group: e?.item?.data?.ItemFamily?.data?.description ? e.item.data.ItemFamily.data.description : '',
                        type: (e.item && e.item.data && e.item.data.itemKinds && e.item.data.itemKinds.data && e.item.data.itemKinds.data[0] && e.item.data.itemKinds.data[0].name) || '',
                        image: e.item?.data?.attachments?.data?.length > 0 && e.item.data.attachments.data.filter(img => img.id === e.item?.data?.attachment_id)[0]?.awsFileUrl,
                        price: e.unit_price || 0,
                        qty: qty,
                        uuid: e.uuid,
                        vat: (e.item && e.item.data && e.item.data.vat) || 0,
                        total: parseFloat(qty * price).toFixed(2)
                    }
                    setRowData(prevState => ([...prevState, tempObj]))
                })
                setDataLoaded(true)
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        // eslint-disable-next-line
    }, [])

    const SetStepOne = () => {
        setStep('one')
    }

    const SetStepTwo = () => {
        setStep('two')
        setRowData2(selectedContent.map(sp => {
            return { ...sp, qty: selectedContentQTYs[sp.uuid] > 0 ? selectedContentQTYs[sp.uuid] : sp.qty }
        }))
    }

    const RequestReturn = () => {
        setDisabled(true)
        setLoadingStatus('loading')
        let isValid = true
        let detail_uuids = []
        let detail_quantitys = []
        let detail_reason_messages = []

        const ifError = () => {
            setDisabled(false)
            setLoadingStatus('waiting')
            dispatch(setIsInfoModalOpen(true))
            dispatch(setContentInfoModal(t('niki.b2b.feedback.error_return_products')+ ' ' + t('niki.b2b.feedback.contact_your_manager')+'.'))
        }

        selectedContent.forEach(e => {
            if (parseInt(e.qtyReturn) > 0 && e.reason.length > 0) {
                detail_uuids.push(e.uuid)
                detail_quantitys.push(e.qtyReturn)
                detail_reason_messages.push(e.reason)
            } else {
                isValid = false
            }
        })
        if (!isValid) {
            document.querySelectorAll(`.input_renderer_returns__input`).forEach(e => {
                if (parseInt(e.value) === 0) e.classList.add('notvalid')
            })
        }

        if (isValid) {
            if (document.querySelector(`.input_renderer_returns__input.notvalid`)) document.querySelectorAll(`.input_renderer_returns__input.notvalid`).forEach(e => e.classList.remove('notvalid'))
            const requestOptionsPost = getRequestOptions('POST', null, 'application/json')
            requestOptionsPost.body = JSON.stringify({
                document_uuid: orderID,
                detail_uuid: detail_uuids,
                detail_quantity: detail_quantitys,
                detail_reason_message: detail_reason_messages,
            })

            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/return-documents`, requestOptionsPost)
                .then(response => response.json())
                .then(json => {
                    if (json.uuid) {
                        dispatch(clearReturnSelected())
                        navigate(`/returnregistered/${json.uuid}/`)
                    } else {
                        ifError()
                    }
                })
                .catch(error => {
                    ifError()
                    console.error(error)
                })
                .finally(() => HideLoader())
        }
    }

    /* custom row */
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.forEachNode(function (node) {
            // node.setExpanded(node.id === '1')
            node.setExpanded(true)
        })
    }, [])
    const detailCellRenderer = useMemo(() => {
        return DetailCellRenderer
    }, [])
    /* custom row */

    return (
        <>
            <div className="breadcrumbs_on_2_colummns">
                <Breadcrumbs itemlist={[
                    { 'url': '/account/', 'title': t('niki.b2b.account.my') },
                    { 'url': '/Invoices/', 'title': t('niki.b2b.menu.myinvoices') },
                    { 'url': `/returnproducts/${orderID}/`, 'title': t('niki.b2b.returnproducts.selectitems') }
                ]} />
                <ol className="cart_steps">
                    <li className={step === 'one' && `current`}>{t('niki.b2b.returnproducts.products')}</li>
                    <li className={step === 'two' && `current`}>{t('niki.b2b.returnproducts.quantities')}</li>
                    <li>{t('niki.b2b.feedback.confirmation')}</li>
                </ol>
            </div>

            <div className="wrapper">
                <h1>{t('niki.b2b.returnproducts.h1')} {returnKey} {returnIDReal ? returnIDReal : ''}</h1>
                <div className="return_products__please">{t('niki.b2b.returnproducts.select')}.</div>
                <div className="return_products__two_columns">
                    <div className="return_products__two_columns__first">
                        {step === 'one' && <>
                            {dataLoaded
                                ? <div className="ag-theme-alpine" style={{ height: 900, width: '100%' }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={rowData}
                                        columnDefs={columnDefsDataOne}
                                        defaultColDef={defaultColDef}
                                        rowHeight="76"
                                        rowStyle={gridStyles}
                                        animateRows={true}
                                        singleClickEdit={true}
                                        onCellClicked={onCellClicked}
                                        onSelectionChanged={onSelectionChanged}
                                        rowSelection={'multiple'}
                                        groupDisplayType={'groupRows'}
                                        groupRowRenderer={'agGroupCellRenderer'}
                                        rowGroupPanelShow={'always'}
                                        localeText={localeText}
                                        suppressDragLeaveHidesColumns={true}
                                        onGridReady={onGridReady}
                                    >
                                    </AgGridReact>
                                </div>
                                : <TablePreloader />
                            }
                        </>}

                        {step === 'two' && <div className="ag-theme-alpine" style={{ height: 900, width: '100%' }}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData2}
                                columnDefs={columnDefsDataTwo}
                                defaultColDef={defaultColDef}
                                rowHeight="76"
                                rowStyle={gridStyles}
                                rowGroupPanelShow={'always'}
                                animateRows={true}
                                masterDetail={true}
                                detailCellRenderer={detailCellRenderer}
                                onFirstDataRendered={onFirstDataRendered}
                                detailRowHeight={86}
                                rowSelection={'false'}
                                suppressDragLeaveHidesColumns={true}
                            >
                            </AgGridReact>
                        </div>}

                    </div>
                    <div className="return_products__two_columns__second">
                        <div className="return_products__aside__unit">
                            <div className="return_products__aside__unit__header">{t('niki.b2b.returnproducts.summary')}</div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.returnproducts.totalselected')}</div>
                                <div>{selectedContent.length}</div>
                            </div>
                            {step === 'one' &&
                                <button
                                    data-selected={selectedContent.length}
                                    className="btn btn-block select_quantities"
                                    onClick={SetStepTwo}
                                >{t('niki.b2b.returnproducts.selectquantities')}<ArrowRight /></button>
                            }
                            {step === 'two' && <>
                                <button
                                    className="btn btn-outline btn-block marginbottom15"
                                    onClick={SetStepOne}
                                ><ArrowRight className="oneeighty" />{t('niki.b2b.returnproducts.modifyquantities')}
                                </button>
                                <button
                                    className={[`btn btn-green btn-block submit_return_button submit_loader`, loadingStatus].join(' ')}
                                    onClick={RequestReturn}
                                    disabled={disabled}
                                >
                                    <span className="waiting">{t('niki.b2b.returnproducts.request')}<ArrowRight /></span>
                                    <div className="spinner_ring loading"><div></div><div></div><div></div><div></div></div>
                                </button>
                            </>}
                        </div>
                        <div className="return_products__aside__unit">
                            <div className="return_products__aside__unit__header">{t('niki.b2b.returnproducts.orderreference')}</div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.feedback.orderid')}</div>
                                <div>{orderData.idReal}</div>
                            </div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.debts.orderdate')}</div>
                                <div>{DateFormat(orderData.date)}</div>
                            </div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.invoiceno')}</div>
                                <div>{orderData.invoice}</div>
                            </div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.debts.processid')}</div>
                                <div>{orderData.processIDReal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const DetailCellRenderer = ({ data }) => {

    const dispatch = useDispatch()
    const uuid = data.uuid
    const [otherReason, setOtherReason] = useState('')
    const otherInputEl = useRef()
    const ReasonsForReturnRedux = useSelector(state => state.returnsContent.reasons)

    useEffect(() => {
        dispatch(updateReturnSelectedReason({ uuid: uuid, reason: ReasonsForReturnRedux[0]?.id }))
        // eslint-disable-next-line
    }, [])

    const ReasonChange = e => {
        let localValue = e.target.value
        const activeInput = () => {
            otherInputEl.current.style.display = 'block'
            otherInputEl.current.focus()
        }
        localValue === '0KkzrXn5gamP2ypM' ? activeInput() : otherInputEl.current.style.display = 'none'
        dispatch(updateReturnSelectedReason({ uuid: uuid, reason: localValue }))
    }

    const OtherReasonChange = e => {
        const localValue = e.target.value
        setOtherReason(localValue)
        // setOtherReasonFull(`Other: ${localValue}`)
        // dispatch(updateReturnSelectedReason({uuid: uuid, reason: `Other: ${localValue}`}))
    }
    return (
        <div className="reason_details">
            <label>{t('niki.b2b.returnproducts.reason')} <span className="red">*</span>:</label>
            <select className="form-control return_reason_select" onChange={ReasonChange}>
                {ReasonsForReturnRedux.map((e, i) => <option key={`reason${i}${uuid}`} value={e.id}>{e.name === "Other" ? t('niki.b2b.returnproducts.other') : e.name}</option>)}
            </select>
            <input
                className="form-control local_input"
                value={otherReason}
                onChange={OtherReasonChange}
                ref={otherInputEl}
                autoFocus={true}
            />
        </div>
    )
}