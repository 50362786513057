import { useEffect, useRef, useState } from "react"
import { useNavigate } from 'react-router-dom';
import axios from "axios"
import { t } from "i18next"

import { getRequestOptions } from '../helpers/apiHelper'
import { xCompanyID, companyCountryShort, feedbackPolicyID, priceLineID, xTenant } from "../config"
import { ShowLoader, HideLoader, LangFix } from "../functions.js"

import { Breadcrumbs } from "../breadcrumbs"
import { ContactsAside } from "../components/contactsaside"
import { Alert } from "../components/alert"
import { AlertPopup } from "../components/AlertPopup"

import './../assets/css/staticpages.css'

import PhoneInput from 'react-phone-input-2'
import es from 'react-phone-input-2/lang/es.json'
import ru from 'react-phone-input-2/lang/ru.json'
import it from 'react-phone-input-2/lang/it.json'
import pt from 'react-phone-input-2/lang/pt.json'
import de from 'react-phone-input-2/lang/de.json'
import fr from 'react-phone-input-2/lang/fr.json'

import { ReactComponent as Upload } from './../assets/svg/feedbackupload.svg'

const DateFormat = localDate => localDate && localDate.length > 0 && localDate.split(' ')[0].replaceAll('/','.')
const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

const CreateFeedback = async (state) => {
    const searchParams = new URLSearchParams(state)
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/feedbacks?${searchParams.toString()}`,getRequestOptions('POST'))
    const json = await response.json()
    return json
}

const GetOrdersData = async () => {
    // const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents?include=details,status&searchFields=account_id%3A%3D%3Bkey%3A%3D&realKey=true&limit=100&search=account_id%3A${localStorage.getItem('account.entity.id.real')}%3Bkey%3ANE`,requestOptions)
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents?include=details,status,documentType&showVat=true&realKey=true&page=1&limit=15&search=documentType.id:1;account_id:${localStorage.getItem('account.entity.id.real')};key:FAC2,FAC&searchFields=key:in;account_id:in;documentType.id:in&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`,getRequestOptions('GET', null, 'application/json'))
    const json = await response.json()
    return json
}
const GetFeedbackTypes = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/feedback-types`,getRequestOptions('GET', null, 'application/json'))
    const json = await response.json()
    return json
}
const GetFeedbackSubTypes = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/feedback-types?include=feedbackSubtypes`,getRequestOptions('GET', null, 'application/json'))
    const json = await response.json()
    return json
}
const defaultDataState = {
    entity_account_id: localStorage.getItem('account.entity.id'),
    process_id: 'yPrdNR7m4nbgEjmq',
    document_id: '',
    type_id: '',
    subtype_id: '',
    status_id: 'J8RvMwowm7k2pNOr',
    matchcode: '',
    reply_name: '',
    telephone: '',
    reply_email: '',
    description: '',
} 

export const SubmitFeedback = () => {
    const navigate = useNavigate()

    const IHaveReadEl = useRef(null)
    const subtypeSelectlEl = useRef(null)
    const subtypeLabelEl = useRef(null)
    const EmailErrorAlertEl = useRef(null)
    const PhoneErrorAlertEl = useRef(null)
    const NameErrorAlertEl = useRef(null)
    const SubjectErrorAlertEl = useRef(null)
    const FormGridEl = useRef(null)

    const [step,setStep] = useState('one')
    const [formSubmited,setFormSubmited] = useState(false)
    const [emailValid,setEmailValid] = useState(false)
    const [phoneValid,setPhoneValid] = useState(false)
    const [subjectValid,setSubjectValid] = useState(false)
    const [nameValid,setNameValid] = useState(false)
    const [messageID,setMessageID] = useState('')
    const [feedbackID,setFeedbackID] = useState('')
    const [uploadedImages,setUploadedImages] = useState([])
    const [feedbackOrders,setFeedbackOrders] = useState([])
    const [feedbackTypes,setFeedbackTypes] = useState([])
    const [feedbackSubTypes,setFeedbackSubTypes] = useState([])
    const [subtypesCount,setSubtypesCount] = useState({})
    const [sendCopyEmail,setSendCopyEmail] = useState('false')
    const [state,setState] = useState(defaultDataState)
    const [loadingStatus, setLoadingStatus] = useState('waiting')
    const [disabled, setDisabled] = useState(false)
    const [isFeedbackPolicyAlert, setIsFeedbackPolicyAlert] = useState(false)
    const [isAcceptTermsAlert, setIsAcceptTermsAlert] = useState(false)

    const CheckSubtypes = value => {
        if (subtypesCount[value] > 0) {
            subtypeLabelEl.current.classList.remove('hidden')
            subtypeSelectlEl.current.classList.remove('hidden')
        } else {
            subtypeLabelEl.current.classList.add('hidden')
            subtypeSelectlEl.current.classList.add('hidden')
        }
    }
    const FormValuesChange = e => {
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value
        setState({...state,[e.target.name]:value})
        e.target.name === 'reply_email' && setEmailValid(isEmail(value))
        e.target.name === 'matchcode' && setSubjectValid(e.target.value.length > 0 ? true : false)
        e.target.name === 'reply_name' && setNameValid(e.target.value.length > 0 ? true : false)
        if (e.target.name === 'type_id') CheckSubtypes(value)
    }
    const onChangeValuePhone = value => {
        setPhoneValid(value.length > 0)
        setState({ ...state, telephone: `+${value}`})
    }

    useEffect(() => {
        feedbackOrders.length > 0 && setState(prev => ({...prev, document_id: feedbackOrders[0].id}))
    }, [feedbackOrders])

    const GoToSecondStep = () => {
        setFormSubmited(true)
        if (IHaveReadEl.current.checked && emailValid && subjectValid) {
            ShowLoader()
            CreateFeedback(state)
            .then(json => {
                if (json.data) {
                    const message_id = json.data.message_id
                    setFeedbackID(json.data.id)
                    setMessageID(json.data.message_id)
                    setState({...state,message_id:message_id})
                    FormGridEl.current.scrollIntoView()
                    setStep('two')
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        } else {
            !IHaveReadEl.current.checked && setIsAcceptTermsAlert(true)
            !emailValid && EmailErrorAlertEl.current.scrollIntoView()
            !subjectValid && SubjectErrorAlertEl.current.scrollIntoView()
        }
    }

    const ConfirmSubmit = () => {
        setDisabled(true)
        setLoadingStatus('loading')
        const requestOptionsComment = getRequestOptions('POST', null, 'application/json')
        requestOptionsComment.body = JSON.stringify({
            text: state.description,
            send_copy_to_email: sendCopyEmail,
            type: 'feedback'
        })
    
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/messages/${messageID}/comments`,requestOptionsComment)
        .then(response => response.json())
        .then(json => {
            if (json.data)  navigate(`/feedback-received/${feedbackID}/`)
        })
        .catch(error => console.error(error))
        .finally(() => {
            HideLoader()
            setLoadingStatus('waiting')
        })
    }

    const SelectFile = e => {
        let data = new FormData()

        const files = Array.from(e.target.files)
        files.forEach(file => data.append('attachments[]',file))

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'X-Tenant': xTenant,
                'X-CompanyID': xCompanyID,
                'Authorization': `Bearer ${localStorage.getItem('access.token')}`,
            },
        }
        ShowLoader()
        axios.post(`${process.env.REACT_APP_API_DOMAIN}/b2b/messages/${messageID}/comment-upload`,data,config)
        .then(res => {
            if (res.data && res.data.length > 0) {
                res.data.forEach(e => {
                    const url = e.url
                    setUploadedImages(prevState => ([...prevState, url]))
                })
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const [typeID,setTypeID] = useState('')
    
    useEffect(() => {
        
        ShowLoader()
        GetOrdersData()
        .then(json => {
            setFeedbackOrders([])
            const feedbackOrdersData = []
            if (json.data && json.data.length > 0) {
                json.data.forEach(e => {
                    const tempObj = {
                        id: e.id,
                        idReal: e.real_id,
                        date: DateFormat(e.created_at),
                        key: `${e.key} - ${e.serial}`
                    }
                    feedbackOrdersData.push(tempObj)
                })
            }
            feedbackOrdersData.sort(compareDates).reverse()
            setFeedbackOrders(feedbackOrdersData)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())

        ShowLoader()
        GetFeedbackTypes()
        .then(json => {
            setFeedbackTypes([])
            json.data && json.data.forEach(e => {
                const tempObj = {
                    id: e.id,
                    name: e.matchcode,
                }
                setFeedbackTypes(prevState => ([...prevState, tempObj]))
            })
            setTypeID(json.data[0].id)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())

        try {
            ShowLoader()
            GetFeedbackSubTypes()
            .then(json => {
                let localSubtypesCount = {}
                json.data && json.data.forEach((e,i) => {
                    setFeedbackSubTypes([])
                    const localType = e.id
                    localSubtypesCount[localType] = (e.feedbackSubtypes && e.feedbackSubtypes.data && e.feedbackSubtypes.data.length) || 0
                    if (i === 0) CheckSubtypes(localType)
                    if (e.feedbackSubtypes && e.feedbackSubtypes.data && e.feedbackSubtypes.data.length > 0) {
                        e.feedbackSubtypes.data.forEach(j => {
                            const tempObj = {
                                id: j.id,
                                name: j.matchcode,
                                type: localType,
                            }

                            setFeedbackSubTypes(prevState => ([...prevState, tempObj]))
                        })
                    }
                })
                setSubtypesCount(localSubtypesCount)
            })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        setState(prev => ({...prev, type_id: typeID, document_id: ''}))
    },[typeID])
   
    const [feedbackPolicyText,setFeedbackPolicyText] = useState('')
  
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/privacy-policies-public`,getRequestOptions())
        .then(response => response.json())
        .then(json => json.data && setFeedbackPolicyText(json.data.filter(e => e.real_id === feedbackPolicyID)[0].body))
    }, [])
    
    const compareDates = (a, b) => {
        const dateA = new Date(a.date.split('.').reverse().join('-'));
        const dateB = new Date(b.date.split('.').reverse().join('-'));
        return dateA - dateB;
    }

    const localLanguage = (lang) => {
        switch (lang) {
            case 'es': return es
            case 'ru': return ru
            case 'pt': return pt
            case 'de': return de
            case 'fr': return fr
            case 'it': return it
            // case 'gr': return getLocatilization()
            default: {}
        }
    }
    const generateLocatilization = () => localLanguage(LangFix(localStorage.getItem('locale')))

    return (
        <>
            <div className="breadcrumbs_on_2_colummns">
                <Breadcrumbs itemlist={[
                    {'url':'/account/','title':t('niki.b2b.account.my')},
                    {'url':'/feedback/','title':t('niki.b2b.menu.feedback')},
                    {'url':'/submit-feedback/','title':t('niki.b2b.feedback.order')},
                ]} />
                {step === 'one' &&
                    <ol className="cart_steps">
                        <li className="current">{t('niki.b2b.feedback.register')}</li>
                        <li>{t('niki.b2b.feedback.details')}</li>
                        <li>{t('niki.b2b.feedback.confirmation')}</li>
                    </ol>
                }
                {step === 'two' &&
                    <ol className="cart_steps">
                        <li>{t('niki.b2b.feedback.register')}</li>
                        <li className="current">{t('niki.b2b.feedback.details')}</li>
                        <li>{t('niki.b2b.feedback.confirmation')}</li>
                    </ol>
                }
            </div>
            <div className="wrapper">
                <h1>{t('niki.b2b.menu.feedback')}</h1>

                <div className="submit_feedback__columns" ref={FormGridEl}>
                    <div>
                        {step === 'one' &&
                            <>
                                <h2>{t('niki.b2b.feedback.step1')}</h2>
                                <p className="paddingbottom30">{t('niki.b2b.feedback.step1sub')}</p>
                            </>
                        }
                        {step === 'two' &&
                            <>
                                <h2>{t('niki.b2b.feedback.step2')}</h2>
                                <p className="paddingbottom30">{t('niki.b2b.feedback.step2sub')}</p>
                            </>
                        }
                        
                        <div className="submit_feedback__form__grid">
                            {step === 'one' &&
                                <>
                                    <label htmlFor="type_id">{t('niki.b2b.feedback.ftype')} <span className="red">*</span></label>
                                    <select
                                        className="form-control"
                                        name="type_id"
                                        id="type_id"
                                        onChange={FormValuesChange}
                                    >
                                    {feedbackTypes.length > 0 && feedbackTypes.map((e,i)=><option key={`feddbacktype${i}`} value={e.id}>{e.name}</option>)}
                                    </select>
                                    
                                    <label ref={subtypeLabelEl} htmlFor="subtype_id">{t('niki.b2b.feedback.subtype')} <span className="red">*</span></label>
                                    <select
                                        ref={subtypeSelectlEl}
                                        className="form-control"
                                        name="subtype_id"
                                        id="subtype_id"
                                        onChange={FormValuesChange}
                                    >
                                    {feedbackSubTypes.length > 0 && feedbackSubTypes.map((e,i)=><option key={`feddbacksubtype${i}`} value={e.id}>{e.name}</option>)}
                                    </select>

                                    {formSubmited && !subjectValid && <>
                                        <div ref={SubjectErrorAlertEl}></div>
                                        <Alert addclass="nomargin" type="danger" text={t('niki.b2b.feedback.subjectalert')} />
                                    </>}
                                    <label htmlFor="matchcode">{t('niki.b2b.feedback.subject')} <span className="red">*</span></label>
                                    <input type="text" className="form-control" id="matchcode" name="matchcode" placeholder={t('niki.b2b.feedback.subject')} onChange={FormValuesChange} value={state.matchcode} required />
                                    
                                    <label htmlFor="document_id">{t('niki.b2b.feedback.referringorder')}</label>
                                    <select
                                        className="form-control"
                                        name="document_id"
                                        id="document_id"
                                        onChange={FormValuesChange}
                                    >
                                    {feedbackOrders.length > 0 && feedbackOrders.map((e,i)  =>
                                        <option value={e.id} key={`feedbackordersli${i}`}>{e.key} {e.idReal} - {e.date}</option>
                                    )}
                                    </select>

                                    <label htmlFor="customernumber">{t('niki.b2b.feedback.customernumber')} <span className="red">*</span></label>
                                    <input type="text" className="form-control disabled" disabled id="customernumber" name="customernumber" value={`${localStorage.getItem('entity_account.external_id')}`} />

                                    {formSubmited && !nameValid && <>
                                        <div ref={NameErrorAlertEl}></div>
                                        <Alert addclass="nomargin" type="danger" text={t('niki.b2b.feedback.namealert')} />
                                    </>}
                                    <label htmlFor="reply_name">{t('niki.b2b.feedback.yourname')} <span className="red">*</span></label>
                                    <input type="text" className="form-control" id="reply_name" name="reply_name" placeholder={t('niki.b2b.deliveryaddresses.name')} required onChange={FormValuesChange} value={state.reply_name} />

                                    {formSubmited && !phoneValid && <>
                                        <div ref={PhoneErrorAlertEl}></div>
                                        <Alert addclass="nomargin" type="danger" text={t('niki.b2b.feedback.phonealert')} />
                                    </>}
                                    <label htmlFor="telephone">{t('niki.b2b.feedback.telephone')} <span className="red">*</span></label>
                                    {/* <input ref={EmailErrorAlertEl} type="text" className="form-control" id="telephone" name="telephone" placeholder={t('niki.b2b.deliveryaddresses.phone')} required onChange={FormValuesChange} value={state.telephone} /> */}
                                    <div className="wrapprePhone">
                                        <PhoneInput
                                            localization={generateLocatilization()}
                                            country={companyCountryShort || 'gb'}
                                            placeholder={t('niki.b2b.login.phonenumber')}
                                            preferredCountries={['it','de','fr','gr','ru','es','pt','gb']}
                                            onChange={onChangeValuePhone}
                                        />
                                    </div>

                                    {formSubmited && !emailValid && <>
                                        <div ref={EmailErrorAlertEl}></div>
                                        <Alert addclass="nomargin" type="danger" text={t('niki.b2b.feedback.emailalert')} />
                                    </>}

                                    <label htmlFor="reply_email">{t('niki.b2b.feedback.replyemail')} <span className="red">*</span></label>
                                    <input type="text" className="form-control" id="reply_email" name="reply_email" placeholder={t('niki.b2b.login.email')} required onChange={FormValuesChange} value={state.reply_email} />
                                </>
                            }
                            
                            {step === 'two' &&
                                <>
                                    <label htmlFor="description">{t('niki.b2b.feedback.description')} <span className="red">*</span></label>
                                    <textarea className="form-control" id="description" name="description" placeholder={t('niki.b2b.feedback.message')} required onChange={FormValuesChange} value={state.description} />
                                    <label htmlFor="attachments">{t('niki.b2b.feedback.attachments')}</label>
                                    <div>
                                        <label className="btn uploadattachments_label"><input type="file" name="file" onChange={SelectFile} multiple accept="image/*" />{t('niki.b2b.feedback.uploadattachments')}<Upload /></label>
                                        {uploadedImages && uploadedImages.length > 0 &&
                                            <div className="submit_feedback__uploaded_images">
                                                {uploadedImages.map((e, i) => <div key={`uploadedimage${i}`} className="submit_feedback__uploaded_image"><img src={e} alt="Uploaded" /></div>)}
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                        
                        {step === 'one' &&
                            <>
                                <div className="feedback_term_of_sale">
                                    <input type="checkbox" ref={IHaveReadEl} />
                                    {t('niki.b2b.feedback.acknowledge')}&nbsp;
                                    <span onClick={() => setIsFeedbackPolicyAlert(true)}>
                                        {t('niki.b2b.feedback.policy')}
                                    </span>
                                </div>
                                <button type="button" className="btn btn-info" onClick={GoToSecondStep}>{t('niki.b2b.feedback.registernext')}</button>
                            </>
                        }

                        {step === 'two' &&
                            <>
                                <label className="feedback_label_checkbox">
                                    <input type="checkbox" onChange={e => setSendCopyEmail(e.target.checked ? 'true' : 'false')} />{t('niki.b2b.feedback.copytoemail')}
                                </label>
                                    <button
                                        type="button"
                                        className={[`btn btn-info final_feedback_submit submit_loader`,loadingStatus].join(' ')}
                                        onClick={ConfirmSubmit}
                                        disabled={!state.description || disabled}
                                    >
                                        <span className="waiting">{t('niki.b2b.feedback.complete')}</span>
                                        <div className="spinner_ring loading"><div></div><div></div><div></div><div></div></div>
                                    </button>
                            </>
                        }                       
                    </div>
                    <div>
                        <div className="submit_feedback__columns__support">
                            <h2>{t('niki.b2b.feedback.help.title')}</h2>
                            <div className="submit_feedback__columns__support__text">{t('niki.b2b.feedback.help.text1')}<br />{t('niki.b2b.feedback.help.text2')}<br />{t('niki.b2b.feedback.help.text3')}</div>
                            <button type="button" onClick={() => setIsFeedbackPolicyAlert(true)} className="submit_feedback__columns__support__button">{t('niki.b2b.feedback.alert.policy')}</button>
                        </div>
                        <div className="contacts__support__header">{t('niki.b2b.account.supportteam')}</div>
                        <ContactsAside />
                    </div>
                </div>
            </div>
            {isFeedbackPolicyAlert && <AlertPopup text={feedbackPolicyText} closeAlert={() => setIsFeedbackPolicyAlert(false)} /> }
            {isAcceptTermsAlert && <AlertPopup text={t('niki.b2b.feedback.pleaseaccept')} closeAlert={() => setIsAcceptTermsAlert(false)}/> }
        </>
    )
}