// import { combineReducers, configureStore } from "redux"
import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "@reduxjs/toolkit"
import cartSlise from "./cartSlice"
import productsSlice from "./productsSlice"
import returnsSlice from "./returnsSlice"
import checkoutSlice from "./checkoutSlice"
import universalSlice from "./universalSlice"
import allProductsSlice from './allproduct'

const rootReducer = combineReducers({
    cartContent: cartSlise,
    productsContent: productsSlice,
    returnsContent: returnsSlice,
    checkoutContent: checkoutSlice,
    universalContent: universalSlice,
    allProductsContent: allProductsSlice,
})

export const store = configureStore({
    reducer: rootReducer,
})