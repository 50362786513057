import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { t } from "i18next"
import { useDispatch, useSelector } from 'react-redux'
import { xCompanyID, companyName, companyUrlAddress, xTenant } from '../config'
import { ShowLoader, HideLoader } from '../functions'
import { setIsNoEntityAccount } from '../reduxToolkit/universalSlice'

import { SnedVerifyCode } from '../components/loginComponents/SnedVerifyCode'
import { AlertPopup } from '../components/AlertPopup'
import { LoginFormFooter } from '../components/loginComponents/LoginFormFooter'
import { getRequestOptionsNoAuth, getBanners } from '../helpers/apiHelper'
import LoadableImage from "../components/LoadableImage/LoadableImage"

import './../assets/css/style.css'
import './../assets/css/loginform.css'

import { ReactComponent as InfoWhite } from '../assets/svg/infowhite.svg'
import { ReactComponent as LoginSvg } from '../assets/svg/login-enter.svg'

import 'react-phone-input-2/lib/style.css'

const GetBannerData = e => {
    const toReturn = {}
    if (e.attachments && e.attachments.data && e.attachments.data[0] && e.attachments.data[0].awsFileUrl) toReturn.image = e.attachments.data[0].awsFileUrl
    if (e.matchcode) toReturn.title = e.matchcode
    if (e.contentUrl && e.contentUrl.data && e.contentUrl.data.url) toReturn.url = e.contentUrl.data.url || ''
    if (toReturn.url) toReturn.target = toReturn.url.includes('http') ? `_blank` : `_self`
    return toReturn
}

const FetchClientInfo = async () => {
    const response = await fetch(`https://geolocation-db.com/json/`)
    const json = await response.json()
    return json
}

const FetchEmail = async (url,requestOptions) => {
    const response = await fetch(url,requestOptions)
    return response.ok
}

export const Login = () => {
    const dispatch = useDispatch()
    const isNoEntityAccount = useSelector(state => state.universalContent.isNoEntityAccount)

    const [email, setEmail] = useState('')
    const [toshow, setToshow] = useState('default')
    const [noEmailInBase, setNoemailinbase] = useState(false)
    const [emailInBase, setEmailInBase] = useState(false)
    const [wrongCode, setWrongCode] = useState(false)
    const [bannerOne, setBannerOne] = useState({})
    const [bannerTwo, setBannerTwo] = useState({})
    const [isCheckCode, setIsCheckCode] = useState(false)

    useEffect(() => {
        if (toshow === 'noemailerror') {
            setNoemailinbase(true)
            setEmailInBase(false)
        }
        if (toshow === 'entercode') {
            setNoemailinbase(false)
            setEmailInBase(true)
        }
    }, [toshow])

    useEffect(() => {
        FetchClientInfo()
        .then(json => {
            localStorage.setItem('client.info',JSON.stringify(json))
        })

        try {
            ShowLoader()
            getBanners().then(json => {
                json.data && json.data.forEach(e => {
                    e.content_subtype_id === 'O5D1YKnGOndeJ9qv' && e.contentType?.data?.real_id === 1 && setBannerOne(GetBannerData(e))
                    e.content_subtype_id === 'J8RvMwowm7k2pNOr' && e.contentType?.data?.real_id === 1 && setBannerTwo(GetBannerData(e))
                })
                if (json.data) {
                    localStorage.setItem('banners_data', JSON.stringify(json.data))
                }
            })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    }, [])

    const submitEmail = e => {
        e.preventDefault()
        const requestOptions = getRequestOptionsNoAuth('POST', null, 'application/json')
        requestOptions.body = JSON.stringify({ email: email })
        const url = `${process.env.REACT_APP_API_DOMAIN}/b2b/login/email-api`
        
        ShowLoader()
        FetchEmail(url,requestOptions).then(response => {
            if (response) {
                setToshow('entercode')
                localStorage.setItem('account.user.email',email)
            } else {
                setToshow('noemailerror')
            }
        })
        .finally(() => HideLoader())

        document.querySelector('.email_field').value = ''
    }

    return (
        <>
            <div className="login_form__wrapper">
                <div className="login_form">
                    <NavLink to="/" className="login_form__logo"><img src={`/logo/${xTenant}_${xCompanyID}.svg`} alt="Logo" /></NavLink> 
                    <div className="login_form__header">{t('niki.b2b.login.into')}<br />{companyName}</div>
                    {!emailInBase && <div className="login_form__sign_up_help login_form__small_text">
                        {t('niki.b2b.login.donthave')}
                        <a href={companyUrlAddress} target="_blank" rel="noopener noreferrer" className="login_form__link" >{t('niki.b2b.login.signup')}</a>
                    </div>}
                    {noEmailInBase && <div className="login_form__error__fedback_alert">
                        <InfoWhite />
                        {t('niki.b2b.login.noemail')}
                    </div>}
                    {wrongCode && <div className="login_form__error__fedback_alert">
                        <InfoWhite />
                        {t('niki.b2b.login.wrongcode')}
                    </div>}

                    <>{emailInBase 
                        ? <SnedVerifyCode from={'email'} setWrongCode={setWrongCode} setEmail={setEmail} status={emailInBase} setIsCheckCode={(val) => setIsCheckCode(val)} /> 
                        :  <form onSubmit={submitEmail}>
                                <input
                                    type="text"
                                    id="enteringEmail"
                                    className="login_form__input email_field"
                                    placeholder={t('niki.b2b.login.email')}
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    required
                                    autoFocus
                            />
                                <button className="btn login_form__button" type="submit">{t('niki.b2b.login.login')}<LoginSvg/></button>
                        </form>
                    }</>
                    
                    <div className="login_form__forget_email login_form__small_text">
                        {t('niki.b2b.login.cannotreceiveemail')}?
                        {isCheckCode
                            ? <span className="login_form__link" style={{color: '#888'}}>{t('niki.b2b.login.recover')}</span>
                            : <NavLink to="/recover-mail/" className="login_form__link">{t('niki.b2b.login.recover')}</NavLink>
                        }
                    </div>
                    <div className="login_form__forget_email login_form__small_text">
                        {t('niki.b2b.login.prefer')}
                        {isCheckCode
                            ? <span className="login_form__link" style={{color: '#888'}}>{t('niki.b2b.login.now')}</span>
                            : <NavLink to="/login-phone/" className="login_form__link">{t('niki.b2b.login.now')}</NavLink>
                        }
                    </div>
                    <LoginFormFooter />
                </div>
                {emailInBase
                    ? <a href={bannerTwo.url} target={bannerTwo.target} rel="noopener noreferrer" className="login_form__banner">
                        <LoadableImage src={bannerTwo.image} alt={bannerTwo.title} />
                    </a>
                    : <a href={bannerOne.url} target={bannerOne.target} rel="noopener noreferrer" className="login_form__banner">
                        <LoadableImage src={bannerOne.image} alt={bannerOne.title} />
                    </a>
                }
            </div>
            <div className="loader"></div>
            {isNoEntityAccount && <AlertPopup text={t('niki.b2b.login.noentityaccounts')} closeAlert={() => dispatch(setIsNoEntityAccount(false))} />}
        </>
    )
}