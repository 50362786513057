import { useEffect, useState } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { t } from 'i18next'
import { ShowLoader, HideLoader } from "../functions"
import { getRequestOptions, getCartsData } from '../helpers/apiHelper'

import { Breadcrumbs } from "../breadcrumbs"
import { ContactsAside } from "../components/contactsaside"

import './../assets/css/staticpages.css'

import { ReactComponent as Account } from './../assets/svg/gridaccount.svg'
import { ReactComponent as Orders } from './../assets/svg/gridorders.svg'
import { ReactComponent as ShoppingCarts } from './../assets/svg/gridshoppingcarts.svg'

const FeedbackReceivedContent = props => {

    const feedbackID = props.feedbackID
    const [realID,setRealID] = useState(0)
    const [numberCarts,setNumberCarts] = useState(0)

    useEffect(() => {
        getCartsData().then(json => json?.data && setNumberCarts(json.data.length))
    }, [])

    useEffect(() => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/feedbacks/${feedbackID}?realKey=1`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data && setRealID(json.data.id_real)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }, [feedbackID])

    return (
        <>
            <div className="breadcrumbs_on_2_colummns">
                <Breadcrumbs itemlist={[
                    {'url':'/account/','title':t('niki.b2b.menu.myaccount')},
                    {'url':'/feedback/','title':t('niki.b2b.menu.feedback')},
                    {'url':`/feedback-received/${feedbackID}/`,'title':t('niki.b2b.feedback.complete')},
                ]} />
                <ol className="cart_steps">
                    <li>{t('niki.b2b.feedback.register')}</li>
                    <li>{t('niki.b2b.feedback.details')}</li>
                    <li className="current">{t('niki.b2b.feedback.confirmation')}</li>
                </ol>
            </div>
            <div className="wrapper">

                <div className="feedback_received__columns">
                    <div>

                        <h1>{t('niki.b2b.feedback.received')}</h1>

                        <div className="feedback_received__red">
                            <h2>{t('niki.b2b.menu.feedback')} {realID}</h2>
                            <div className="feedback_received__red__text">{t('niki.b2b.feedback.received1')}<br />{t('niki.b2b.feedback.received2')}</div>
                        </div>

                        <h2>{t('niki.b2b.feedback.otheroptons')}</h2>
                        <ul className="feedback_received__grid">
                            <li><NavLink to="/account/"><Account />{t('niki.b2b.menu.myaccount')}</NavLink></li>
                            <li><NavLink to="/orders/"><Orders />{t('niki.b2b.account.orders')}</NavLink></li>
                            <li>
                                <NavLink to="/shoppingcarts/">
                                    <ShoppingCarts />
                                    {t('niki.b2b.cart.shoppingcarts')}
                                    {numberCarts > 0 && <span className="grid_alert">{numberCarts}</span>}
                                </NavLink>
                            </li>
                        </ul>

                    </div>
                    <div>
                        <div className="contacts__support__header">{t('niki.b2b.account.supportteam')}</div>
                        <ContactsAside />
                    </div>
                </div>
            </div>
        </>
    )
}

export const FeedbackReceived = () => {
    const { id } = useParams()
    return (
        <FeedbackReceivedContent feedbackID={id} />
    )
}